import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {getStatus} from '../../bl-detail/note_request'
import {getUsers} from '../../../../../modules/apps/user-management/users-list/core/_requests'
import {User, useAuth} from '../../../../../modules/auth'
import DatePickerComponent from './DatePicker'
import moment from 'moment'
import {getPpjk} from '../../../../../company/ppjk/ppjk-list/core/_requests'
import {HoPermission} from '../../../../../../_metronic/helpers/userAccess'

type statusModel = {
  _id: string
  name: string
}

export type dateProps = {
  start_date?: string
  end_date?: string
}

const AllBlFilter = () => {
  const {updateState} = useQueryRequest()
  const {currentUser} = useAuth()
  const {isLoading} = useQueryResponse()
  const [datePicker, setDatePicker] = useState<dateProps>({
    start_date: undefined,
    end_date: undefined,
  })
  const [checker, setChecker] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [createdBy, setCreatedBy] = useState<string>('')
  const [company, setCompany] = useState<string>('')
  const [initCompany, setInitCompany] = useState<any>()
  const [initStatus, setInitStatus] = useState<statusModel[]>()
  const [initChecker, setInitChecker] = useState<User[] | undefined>()
  const [initCreator, setInitCreator] = useState<User[] | undefined>()
  const [openFilter, setOpenFilter] = useState<boolean>(false)

  useEffect(() => {
    MenuComponent.reinitialization()
    getStatus().then((result) => {
      setInitStatus(result)
    })

    getUsers().then((result) => {
      const users = result.data
      const checker: any = users?.filter((user) => user.role == 'ho')
      const creator: any = users?.filter((user) => user.role == 'ppjk')
      setInitChecker(checker)
      setInitCreator(creator)
      console.log(checker)
    })

    getPpjk('page=1').then((ppjk) => {
      console.log('ppjk', ppjk)
      setInitCompany(ppjk.data)
    })
  }, [isLoading])

  const resetData = () => {
    setChecker('')
    setStatus('')
    setCreatedBy('')
    setCompany('')
    setDatePicker({start_date: undefined, end_date: undefined})
  }

  const changeDataHanlder = (date?: any) => {
    if (!date) {
      setDatePicker({start_date: '', end_date: ''})
    } else {
      console.log('CEKEKEKE', date[0].$d)
      const start_date = moment(date[0].$d).format('YYYY-MM-DD')
      const end_date = moment(date[1].$d).format('YYYY-MM-DD')
      setDatePicker({start_date, end_date})
    }
  }
  console.log('CEKEKEKE', initCompany)

  const filterData = () => {
    updateState({
      sortCompany: company,
      start_date: datePicker.start_date,
      end_date: datePicker.end_date,
      filter: {checker: checker, status: status, created_by: createdBy},

      ...initialQueryState,
    })
    setOpenFilter((prev) => !prev)
  }
  console.log(initStatus)

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3 h-45px'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={() => setOpenFilter((prev) => !prev)}
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}

      {openFilter && (
        <div>
          <div
            className=' w-300px w-md-325px'
            // className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
            data-kt-menu='true'
            style={{
              // display: 'absolute',
              willChange: 'transform',
              // zIndex: 105,
              position: 'absolute',
              // inset: '0px 0px auto auto',
              margin: '0px',
              // transform: 'translate(-174px, 177px)',
              backgroundColor: 'var(--kt-card-bg)',
              borderRadius: '0.475rem',
              flexDirection: 'column',
              boxShadow: 'var(--kt-menu-dropdown-box-shadow)',
              listStyle: 'none',
              right: '20%',
            }}
          >
            {/* begin::Header */}
            <div className='px-7 py-5 d-flex justify-content-between align-items-center'>
              <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => setOpenFilter((prev) => !prev)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            {/* end::Header */}

            {/* begin::Separator */}
            <div className='separator border-gray-200'></div>
            {/* end::Separator */}

            {/* begin::Content */}
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              {/* begin::Input group */}

              {/* DATE Filter */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Date :</label>
                <div
                  data-allow-clear='true'
                  data-kt-user-table-filter='date'
                  data-hide-search='true'
                >
                  <DatePickerComponent
                    changeDataHanlder={(date: any) => changeDataHanlder(date)}
                    datePicker={datePicker}
                  />
                </div>
              </div>

              <div className='mb-5'>
                <label className='form-label fs-6 fw-bold'>Status :</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='status'
                  data-hide-search='true'
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option value=''></option>
                  {initStatus?.map((stat) => (
                    <option value={stat._id} key={stat._id}>
                      {stat.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* end::Input group */}
              {/* begin::Input group */}
              <div className='mb-5'>
                <label className='form-label fs-6 fw-bold'>Checker :</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='checker'
                  data-hide-search='true'
                  onChange={(e) => setChecker(e.target.value)}
                  value={checker}
                >
                  <option value=''></option>
                  {initChecker?.map((checker) => (
                    <option value={checker._id} key={checker._id}>
                      {checker.firstName}
                    </option>
                  ))}
                </select>
              </div>
              {/* end::Input group */}
              {/* begin::Input group */}
              <div className='mb-5'>
                <label className='form-label fs-6 fw-bold'>Created by :</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='created_by'
                  data-hide-search='true'
                  onChange={(e) => setCreatedBy(e.target.value)}
                  value={createdBy}
                >
                  <option value=''></option>
                  {initCreator?.map((creator) => (
                    <option value={creator._id} key={creator._id}>
                      {creator.firstName}
                    </option>
                  ))}
                </select>
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              {HoPermission(currentUser) && (
                <div className='mb-5'>
                  <label className='form-label fs-6 fw-bold'>Company :</label>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='company'
                    data-hide-search='true'
                    onChange={(e) => setCompany(e.target.value)}
                    value={company}
                  >
                    <option value=''></option>
                    {initCompany?.map((comp: any) => (
                      <option value={comp._id} key={comp._id}>
                        {comp.companyName}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {/* end::Input group */}

              {/* begin::Actions */}
              <div className='d-flex justify-content-end'>
                <button
                  type='button'
                  disabled={isLoading}
                  onClick={resetData}
                  className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                  data-kt-menu-dismiss='false'
                  data-kt-user-table-filter='reset'
                >
                  Reset
                </button>
                <button
                  disabled={isLoading}
                  type='button'
                  onClick={filterData}
                  className='btn btn-primary fw-bold px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                >
                  Apply
                </button>
              </div>
              {/* end::Actions */}
            </div>
            {/* end::Content */}
          </div>
        </div>
      )}
      {/* end::SubMenu */}
    </>
  )
}

export {AllBlFilter}
