import React, {useCallback, useEffect, useState} from 'react'
import {FileError, FileRejection, useDropzone, DropEvent} from 'react-dropzone'
import {stringLimit} from '../stringLimit'
import {toAbsoluteUrl} from '..'
import {DownloadedFilename} from '../dowloadedFilename'
import clsx from 'clsx'

type Props = {
  fieldName: string
  formik: any
}
export interface UploaddableFiles {
  file: File
  errors: FileError[]
}

export interface initFile {
  filename: string
}

const UploadFile: React.FC<Props> = ({fieldName, formik}) => {
  const [files, setFiles] = useState<UploaddableFiles[]>([])
  const [initFiles, setInitFiles] = useState<initFile[] | undefined>([])
  const [deleteInitFile, setDeleteInitFile] = useState<string[]>([])

  const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
    const mappedAcc = accFiles.map((file) => ({file, errors: []}))
    setFiles((curr: UploaddableFiles[]) => [...curr, ...mappedAcc, ...rejFiles])
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  useEffect(() => {
    setInitFiles(formik.initialValues[fieldName])
  }, [])

  useEffect(() => {
    let mount = true
    mount && formik.setFieldValue(fieldName, files)
    formik.setFieldValue(`init_${fieldName}`, initFiles)
    formik.setFieldValue(`delete_${fieldName}`, deleteInitFile)
    return () => {
      mount = false
    }
  }, [files, initFiles])

  const deleteFileFromState = (filename: string) => {
    const currentFile = files?.filter((file) => file.file.name !== filename)
    setFiles(currentFile)
  }

  const deleteInitFileFromState = (filename: string) => {
    const currentFile = initFiles?.filter((file) => file.filename !== filename)
    setInitFiles(currentFile)
    setDeleteInitFile((prevState) => [...prevState, filename])
  }

  const deleteFile = useCallback(
    (filename: any) => {
      deleteInitFileFromState(filename)
    },
    [setDeleteInitFile]
  )

  return (
    <div className='d-flex  justify-content-between'>
      <div {...getRootProps()} className='mb-lg-0 mb-2'>
        <input name={fieldName} {...getInputProps()} />
        <a className='dropzone-select btn btn-sm btn-secondary me-2'>Attach files</a>
      </div>
      <div>
        {initFiles?.map((file, i) => (
          <div
            className='dropzone-filename d-grid mb-2'
            style={{minWidth: '200px', color: '#eea47f'}}
          >
            <div
              className='d-flex flex-center justify-content-start p-1'
              // style={{border: '1px solid #c7966d'}}
            >
              {/* <i className='bi bi-file-earmark-pdf-fill fa' style={{color: '#eea47f'}} /> */}
              <img className='w-15px' src={toAbsoluteUrl('/media/svg/files/pdf.svg')} />
              <span
                key={i}
                data-dz-name
                className='px-2'
                title={file.filename.length > 30 ? file.filename : undefined}
                style={{flexGrow: 2, fontSize: '.9rem'}}
              >
                <>{stringLimit(DownloadedFilename(file.filename), 30)}</>
              </span>
              <i
                title='Delete'
                className='btn bi bi-trash fa p-0'
                style={{color: '#eea47f'}}
                onClick={() => deleteFile(file.filename)}
              />
            </div>
          </div>
        ))}
        {files.map((file, i) => (
          <div className='dropzone-filename text-warning d-grid mb-2' style={{minWidth: '200px'}}>
            <div className='d-flex flex-center justify-content-start p-1'>
              {/* <i className='bi bi-file-earmark-pdf-fill fa-lg text-warning' /> */}
              <img className='w-15px' src={toAbsoluteUrl('/media/svg/files/pdf.svg')} />
              <span
                key={i}
                data-dz-name
                className='px-2'
                title={file.file.name.length > 30 ? file.file.name : undefined}
                style={{flexGrow: 2, fontSize: '.9rem'}}
              >
                <>{stringLimit(file.file.name, 30)}</>
              </span>
              <i
                title='Delete'
                className='btn bi bi-trash fa-lg p-0'
                style={{color: '#fb9600'}}
                onClick={() => deleteFileFromState(file.file.name)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(UploadFile)
