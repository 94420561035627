import axios from 'axios'
import {AuthModel} from './_models'
import {REFRESH_TOKEN, GRACE_API} from './_requests'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  // const {auth} = useAuth()
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  const showViewValue: string | null = localStorage.getItem('show-bl-record')
  const showAllBLViewValue: string | null = localStorage.getItem('show-all-bl-record')
  const showShipmentViewValue: string | null = localStorage.getItem('show-shipment-record')
  if (!showViewValue || !showAllBLViewValue || !showShipmentViewValue) {
    localStorage.setItem('show-bl-record', '20')
    localStorage.setItem('show-all-bl-record', '20')
    localStorage.setItem('show-shipment-record', '20')
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      // const currentDate = new Date()
      // if (+auth.expToken * 1000 < currentDate.getTime()) {
      //   return
      // }
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    async (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      const currentDate = new Date()
      // if (auth && +auth.expToken * 1000 < currentDate.getTime()) {
      //   const response = await axios.get(REFRESH_TOKEN)
      //   config.headers.Authorization = `Bearer ${response.accessToken}`
      // }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
