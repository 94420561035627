import {FC} from 'react'

type Props = {
  color?: string
}

const BlTrackCell: FC<Props> = ({color}) => {
  let track
  switch (color) {
    case 'red':
      track = 'badge badge-light-danger fw-bolder'
      break
    case 'yellow':
      track = 'badge badge-light-warning fw-bolder'
      break
    case 'green':
      track = 'badge badge-light-success fw-bolder'
      break

    default:
      break
  }
  return <> {color && <div className={track}>{color}</div>}</>
}

export {BlTrackCell}
