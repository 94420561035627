import {FC} from 'react'
import {useQueryRequest} from '../../core/QueryRequestProvider'

type Props = {
  number: string
}

const NoCell: FC<Props> = ({number}) => {
  const {state} = useQueryRequest()
  const numberOfDocument = state.items_per_page * state.page - state.items_per_page + number + 1
  console.log(state)

  return <div className='fs-7 fw-bolder'>{numberOfDocument}</div>
}

export {NoCell}
