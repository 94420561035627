import {Modal} from 'react-bootstrap'
import {UseMutationResult} from 'react-query'
import {KTSVG} from './KTSVG'

type Props = {
  show: boolean
  handleClose: () => void
  deleteItem: UseMutationResult<void, unknown, void, unknown>
}

const DeletedModal = ({show, handleClose, deleteItem}: Props) => {
  return (
    <Modal
      id='kt_modal_delete_data'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-300px m-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-body'>
            <h5 className='text-center'>Delete this item?</h5>
          </div>
          <div className='modal-footer m-auto'>
            <button
              type='button'
              className='btn btn-danger btn-sm'
              onClick={async () => await deleteItem.mutateAsync()}
            >
              Delete
            </button>
            <button
              type='button'
              className='btn btn-light btn-sm'
              data-bs-dismiss='modal'
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeletedModal
