import {useQuery} from 'react-query'
import {EditModalForm} from './EditModalForm'
import {isNotEmpty, QUERIES} from '../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getPpjkById} from '../core/_requests'

const EditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  console.log('itemIdForUpdate', itemIdForUpdate)

  const {
    isLoading,
    data: PPJK,
    error,
  } = useQuery(
    `${QUERIES.PPJK_LIST}-${itemIdForUpdate}`,
    () => {
      return getPpjkById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  console.log('PPJK modal', PPJK)

  if (!itemIdForUpdate) {
    return <EditModalForm isUserLoading={isLoading} PPJK={{_id: undefined}} />
  }

  if (!isLoading && !error && PPJK) {
    return <EditModalForm isUserLoading={isLoading} PPJK={PPJK.data} />
  }

  return null
}

export {EditModalFormWrapper}
