import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {getStatus} from '../../bl-detail/note_request'
import {getUsers} from '../../../../../modules/apps/user-management/users-list/core/_requests'
import {User} from '../../../../../modules/auth'

type statusModel = {
  _id: string
  name: string
}

const BlFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [checker, setChecker] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [createdBy, setCreatedBy] = useState<string>('')
  const [initStatus, setInitStatus] = useState<statusModel[]>()
  const [initChecker, setInitChecker] = useState<User[] | undefined>()
  const [initCreator, setInitCreator] = useState<User[] | undefined>()
  const [openFilter, setOpenFilter] = useState<boolean>(false)

  useEffect(() => {
    MenuComponent.reinitialization()
    getStatus().then((result) => {
      setInitStatus(result)
    })

    getUsers().then((result) => {
      const users = result.data
      const checker: any = users?.filter((user) => user.role == 'ho')
      const creator: any = users?.filter((user) => user.role == 'ppjk')
      setInitChecker(checker)
      setInitCreator(creator)
      console.log(checker)
    })
  }, [isLoading])

  const resetData = () => {
    setChecker('')
    setStatus('')
    setCreatedBy('')
  }

  const filterData = () => {
    updateState({
      filter: {checker: checker, status: status, created_by: createdBy},
      ...initialQueryState,
    })
    setOpenFilter((prev) => !prev)
  }
  console.log(initStatus)

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        onClick={() => setOpenFilter((prev) => !prev)}
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      {/* <div className=''> */}
      {openFilter && (
        <div>
          <div
            className=' w-300px w-md-325px'
            // className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
            data-kt-menu='true'
            style={{
              // display: 'absolute',
              willChange: 'transform',
              // zIndex: 105,
              position: 'absolute',
              // inset: '0px 0px auto auto',
              margin: '0px',
              // transform: 'translate(-174px, 177px)',
              backgroundColor: 'var(--kt-card-bg)',
              borderRadius: '0.475rem',
              flexDirection: 'column',
              boxShadow: 'var(--kt-menu-dropdown-box-shadow)',
              listStyle: 'none',
              right: '20%',
            }}
          >
            {/* begin::Header */}
            <div className='px-7 py-5 d-flex justify-content-between align-items-center'>
              <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => setOpenFilter((prev) => !prev)}
                data-kt-menu-dismiss='true'
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            {/* end::Header */}

            {/* begin::Separator */}
            <div className='separator border-gray-200'></div>
            {/* end::Separator */}

            {/* begin::Content */}
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              {/* begin::Input group */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Status:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='two-step'
                  data-hide-search='true'
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option value=''></option>
                  {initStatus?.map((stat) => (
                    <option value={stat._id} key={stat._id}>
                      {stat.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Checker:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
                  onChange={(e) => setChecker(e.target.value)}
                  value={checker}
                >
                  <option value=''></option>
                  {initChecker?.map((checker) => (
                    <option value={checker._id} key={checker._id}>
                      {checker.firstName}
                    </option>
                  ))}
                </select>
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>Created by:</label>
                <select
                  className='form-select form-select-solid fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
                  onChange={(e) => setCreatedBy(e.target.value)}
                  value={createdBy}
                >
                  <option value=''></option>
                  {initCreator?.map((creator) => (
                    <option value={creator._id} key={creator._id}>
                      {creator.firstName}
                    </option>
                  ))}
                </select>
              </div>
              {/* end::Input group */}

              {/* begin::Actions */}
              <div className='d-flex justify-content-end'>
                <button
                  type='button'
                  disabled={isLoading}
                  onClick={resetData}
                  className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                  data-kt-menu-dismiss='false'
                  data-kt-user-table-filter='reset'
                >
                  Reset
                </button>
                <button
                  disabled={isLoading}
                  type='button'
                  onClick={filterData}
                  className='btn btn-primary fw-bold px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                >
                  Apply
                </button>
              </div>
              {/* end::Actions */}
            </div>
            {/* end::Content */}
          </div>
        </div>
      )}
      {/* end::SubMenu */}
    </>
  )
}

export {BlFilter}
