/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo, useState} from 'react'
import {useFormik} from 'formik'
import {useAuth} from '../../../../modules/auth'
import {ROLES} from '../../../../../_metronic/helpers/roles'
import {useParams} from 'react-router-dom'
import {useMutation, useQueryClient} from 'react-query'
import {addCheckerManually, getBLById, updateBL} from '../core/_requests'
import {getPpjkAndUser} from '../../../../checker/checker-list/core/_requests'
import {Modal} from 'react-bootstrap'
import {ID, QUERIES, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../core/QueryRequestProvider'

export type statusModel = {
  _id: string
  firstName: string
  lasttName: string
}

type Props = {
  show: boolean
  handleClose: () => void
  blId: ID
}

export function AddCheckerModal({handleClose, show, blId}: Props) {
  const [users, setUsers] = useState<statusModel[]>()
  const {currentUser} = useAuth()
  const {id} = useParams()
  const {state} = useQueryRequest()
  const queryClient = useQueryClient()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const noteForEdit = {
    user: '',
  }

  useEffect(() => {
    getPpjkAndUser().then((result) => {
      setUsers(result.data.user)
    })
  }, [])

  const reFetchNote = useMutation(() => getBLById(id), {
    // 💡 response of the mutation is passed to onSuccess

    onSuccess: () => {
      // ✅ update detail view directly
      console.log('successs UPDATE')
      queryClient.invalidateQueries(`${QUERIES.BL_LIST}-${query}`)
    },
  })

  const formik = useFormik({
    initialValues: noteForEdit,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      console.log(values)
      try {
        await addCheckerManually(blId, values.user)
        await reFetchNote.mutateAsync()
        handleClose()
        resetForm()
      } catch (error: any) {
        console.log(error.response.data)
      }
    },
  })

  return (
    <Modal
      id='kt_modal_delete_data'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-300px m-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-body'>
            <div className='fs-5 text-dark text-center fw-bolder'>Add Chekcer</div>
          </div>

          <div className='separator border-gray-200'></div>

          <form onSubmit={formik.handleSubmit}>
            <div className='px-7 py-5'>
              <div className='mb-10'>
                {/* <label className='form-label fw-bold'>Select Checker:</label> */}

                <div>
                  <select
                    className='form-select form-select-solid'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    // defaultValue={'1'}
                    {...formik.getFieldProps('user')}
                  >
                    <option value=''>Select Checker</option>
                    {users?.map((user) => (
                      <option value={user._id} key={user._id}>
                        {user.firstName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  type='reset'
                  className='btn btn-sm btn-light btn-active-light-primary me-2'
                  data-kt-menu-dismiss='true'
                  onClick={handleClose}
                >
                  Cancel
                </button>

                <button
                  type='submit'
                  className='btn btn-sm btn-primary'
                  data-kt-menu-dismiss='true'
                >
                  Save
                </button>
              </div>
            </div>
          </form>
          {/* </div> */}
        </div>
      </div>
    </Modal>
  )
}
