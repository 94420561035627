export function SPPBExpired(date: any) {
  const currentDate = +Date.now()
  const created = new Date(date)
  const expiredTime = created.getTime() + 60 * 60 * 1000

  if (currentDate > expiredTime) {
    return true
  } else {
    return false
  }
}
