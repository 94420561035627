import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {PPJK} from '../core/_models'
import {getPpjkAndUser} from '../core/_requests'
import {User} from '../../../modules/auth'

type Props = {
  isUserLoading: boolean
  formik: any
}

function CheckerInput({isUserLoading, formik}: Props) {
  const [company, setCompany] = useState<PPJK[]>()
  const [users, setUsers] = useState<User[]>()

  useEffect(() => {
    getPpjkAndUser().then((response) => {
      setCompany(response.data.ppjk)
      setUsers(response.data.user)
    })
  }, [])

  console.log(users)

  return (
    <>
      {/* begin::Form row */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Company</label>
          </div>
        </div>
        <select
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.company && formik.errors.company},
            {
              'is-valid': formik.touched.company && !formik.errors.company,
            }
          )}
          data-control='select2'
          data-placeholder='Select Company...'
          {...formik.getFieldProps('company')}
        >
          <option value=''>Select Company...</option>

          {/* BEGIN RENDER COMPANY OPTION */}
          {company?.map((comp) => (
            <option value={comp._id} key={comp._id}>
              {comp.companyName}
            </option>
          ))}
          {/* END RENDER COMPANY OPTION */}
        </select>

        {formik.touched.company && formik.errors.company && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.company}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form row */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Checker</label>
          </div>
        </div>
        <select
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.user && formik.errors.user},
            {
              'is-valid': formik.touched.user && !formik.errors.user,
            }
          )}
          data-control='select2'
          data-placeholder='Select user...'
          {...formik.getFieldProps('user')}
        >
          <option value=''>Select user</option>
          {users?.map(
            (user) =>
              user._id && (
                <option
                  value={user._id}
                  key={user._id}
                >{`${user.firstName} ${user.lastName}`}</option>
              )
          )}
        </select>

        {formik.touched.user && formik.errors.user && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.user}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
    </>
  )
}

export default React.memo(CheckerInput)
