/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import BlListSearchComponent from './BlListSearchComponent'
import {
  useQueryResponseAllDataBl,
  useQueryResponsePagination,
} from '../../core/AllBLContextProvider'
import {countToday, countWeek} from '../../../../../../_metronic/helpers/time/CountDocByTime'
import {useAuth} from '../../../../../modules/auth'
import {AllBlFilter} from './AllBlFilter'

const AllBlHeader: React.FC = () => {
  const {currentUser} = useAuth()
  const allDataBl = useQueryResponseAllDataBl()
  const totalDataBl = useQueryResponsePagination()
  const total = totalDataBl.total ? totalDataBl.total : 0

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <span className='text-gray-800 fs-2 fw-bolder me-1'>Bill of Lading</span>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com006.svg'
                      className='svg-icon-4 me-1'
                    />
                    Grace
                  </a>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen018.svg'
                      className='svg-icon-4 me-1'
                    />
                    ID, Jakarta
                  </a>
                </div>
              </div>

              {/* <div className='d-flex flex-wrap my-1'> */}
              <div className='card-toolbar d-flex flex-wrap'>
                <BlListSearchComponent />
                <AllBlFilter />
              </div>

              {/* <a href='#' className='btn btn-sm btn-light me-2' id='kt_user_follow_button'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr012.svg'
                    className='svg-icon-3 d-none'
                  />

                  <span className='indicator-label'>Follow</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </a> */}
              {/* <div className='me-0'>
                <button
                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-three-dots fs-3'></i>
                </button>
                <Dropdown1 />
              </div> */}
            </div>

            <div className='d-flex justify-content-start my-4'></div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/graphs/gra001.svg'
                        className='svg-icon-3 svg-icon-info me-2'
                      />
                      <div className='fs-2 fw-bolder'>{countToday(allDataBl)}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Today</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/graphs/gra001.svg'
                        className='svg-icon-3 svg-icon-warning me-2'
                      />
                      <div className='fs-2 fw-bolder'>{countWeek(allDataBl)}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Week</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen005.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bolder'>{total}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Total</div>
                  </div>
                </div>
              </div>

              <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'></span>
                  <span className='fw-bolder fs-6'></span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/crafted/account/overview' && 'active')
                }
                to='/crafted/account/overview'
              >
                Overview
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/crafted/account/settings' && 'active')
                }
                to='/crafted/account/settings'
              >
                Settings
              </Link>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  )
}

export {AllBlHeader}
