import {useQuery} from 'react-query'
import {BlEditModalForm} from './BlEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getBLById} from '../core/_requests'

const BlEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  console.log('itemIdForUpdate', itemIdForUpdate)

  const {
    isLoading,
    data: BL,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getBLById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  console.log('BL modal', BL)

  if (!itemIdForUpdate) {
    return <BlEditModalForm isUserLoading={isLoading} BL={{_id: undefined}} />
  }

  if (!isLoading && !error && BL) {
    return <BlEditModalForm isUserLoading={isLoading} BL={BL.data} />
  }

  return null
}

export {BlEditModalFormWrapper}
