// @ts-nocheck
import {Column} from 'react-table'
import {BlInfoCell} from './BlInfoCell'
import {BlLastUpdateCell} from './BlLastUpdateCell'
import {CreatedByCell} from './CreatedByCell'
import {BlActionsCell} from './BlActionsCell'
import {BlCustomHeader} from './BlCustomHeader'
import {BlSelectionCell} from './BlSelectionCell'
import {BlSelectionHeader} from './BlSelectionHeader'
import {BL, ErrorHandle} from '../../core/_models'
import {BlCreatedCell} from './BlCreatedCell'
import {BlTrackCell} from './BlTrackCell'
import {BlStatusCell} from './BlStatusCell'
import {StatusIconCell} from './StatusIconCell'
import {NoCell} from './NoCell'

const BlColumns: ReadonlyArray<Column<BL>> = [
  {
    Header: (props) => <BlCustomHeader tableProps={props} title='No' className='min-w-20px' />,
    id: 'no',
    Cell: ({...props}) => <NoCell number={props.row.index} />,
  },
  {
    Header: (props) => <div tableProps={props} />,
    id: 'check_status',
    Cell: ({...props}) => (
      <StatusIconCell status={props.data[props.row.index].check_status?.name} />
    ),
  },
  {
    Header: (props) => (
      <BlCustomHeader tableProps={props} title='Bill of Lading' className='min-w-150px' />
    ),
    id: 'bl_number',
    Cell: ({...props}) => <BlInfoCell bl={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <BlCustomHeader tableProps={props} title='AJU' className='min-w-125px' />,
    accessor: 'aju_number',
  },

  {
    Header: (props) => <BlCustomHeader tableProps={props} title='Track' className='min-w-125px' />,
    id: 'track',
    Cell: ({...props}) => <BlTrackCell color={props.data[props.row.index].track} />,
  },
  {
    Header: (props) => <BlCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    Cell: ({...props}) => <BlStatusCell status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <BlCustomHeader tableProps={props} title='Created at' className='min-w-125px' />
    ),
    id: 'created_at',
    Cell: ({...props}) => <BlLastUpdateCell last_update={props.data[props.row.index].created_at} />,
  },
  {
    Header: (props) => (
      <BlCustomHeader tableProps={props} title='Last Update' className='min-w-125px' />
    ),
    id: 'last_update',
    Cell: ({...props}) => (
      <BlLastUpdateCell last_update={props.data[props.row.index].last_update} />
    ),
  },
  {
    Header: (props) => (
      <BlCustomHeader tableProps={props} title='Checker' className='min-w-125px' />
    ),
    accessor: 'checker.firstName',
    // Cell: ({...props}) => <BlStatusCell status={props.data[props.row.index].checker.firstName} />,
  },
  {
    Header: (props) => (
      <BlCustomHeader tableProps={props} title='Created by' className='min-w-125px' />
    ),
    id: 'created_by',
    Cell: ({...props}) => (
      <CreatedByCell
        created_by={props.data[props.row.index].created_by?.firstName}
        company_name={props.data[props.row.index].created_by_company.companyName}
      />
    ),
  },
  {
    Header: (props) => (
      <BlCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <BlActionsCell
        id={props.data[props.row.index]._id}
        company_id={props.data[props.row.index].created_by_company._id}
        sppb_date={props.data[props.row.index].sppb_upload_at}
      />
    ),
  },
]

export {BlColumns}
