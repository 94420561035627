import {FC} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'

type Props = {
  status?: string
}

const StatusIconCell: FC<Props> = ({status}) => {
  let color = 'warning'
  const icon = (status?: string) => {
    switch (status) {
      case 'Pending':
        color = 'warning'
        return 'gen013'

      case 'Revision':
        color = 'danger'
        return 'gen044'

      case 'Revision Improvement':
        color = 'primary'
        return 'gen055'

      case 'Approved':
        color = 'success'
        return 'gen048'

      default:
        color = 'warning'
        return 'gen013'
    }
  }

  return (
    <div title={status}>
      <KTSVG
        path={`/media/icons/duotune/general/${icon(status)}.svg`}
        className={`svg-icon-${color} svg-icon-2`}
      />
    </div>
  )
}

export {StatusIconCell}
