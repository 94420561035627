/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const BlListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }
    console.log('BL Pagination', pagination.items_per_page)
    updateState({page, items_per_page: pagination.items_per_page || 10})
  }

  console.log(pagination)

  return (
    <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
      <div id='kt_table_users_paginate'>
        <ul className='pagination'>
          {pagination.links
            ?.map((link) => {
              return {...link, label: mappedLabel(link.label)}
            })
            .map((link) => (
              <li
                key={link.label}
                className={clsx('page-item', {
                  active: link.active,
                  disabled: isLoading,
                  previous: link.label === 'Previous',
                  next: link.label === 'Next',
                })}
              >
                <a
                  className={clsx('page-link', {
                    'page-text': link.label === 'Previous' || link.label === 'Next',
                    'me-5': link.label === 'Previous',
                  })}
                  onClick={() => updatePage(link.page)}
                  style={{cursor: 'pointer'}}
                >
                  {mappedLabel(link.label)}
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export {BlListPagination}
