import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {useListView} from './checker-list/core/ListViewProvider'
import {EditModal} from './checker-list/edit-modal/EditModal'
import {ListWrapper} from './checker-list/List'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Company',
    path: '/data/company/ppjk',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CheckerPage: React.FC = () => {
  // console.log(Outlet);
  const {itemIdForUpdate} = useListView()

  return (
    <Routes>
      <Route
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Checker</PageTitle>
            <ListWrapper />
            {itemIdForUpdate !== undefined && <EditModal />}
          </>
        }
      >
        <Route index element={<Navigate to='/data/company/checker' />} />
      </Route>
    </Routes>
  )
}

export default CheckerPage
