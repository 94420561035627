import {FC} from 'react'
import {ExpiredCheck} from '../../../../../../_metronic/helpers/time/ExpiredCheck'

type Props = {
  status?: string
}

const BlStatusCell: FC<Props> = ({status}) => {
  return <div style={{fontSize: '.9rem', fontWeight: 'bolder'}}>{status?.toUpperCase()}</div>
}

export {BlStatusCell}
