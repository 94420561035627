import axios, {AxiosResponse, AxiosError} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {BL, UsersQueryResponse} from './_models'
import FileDownload from 'js-file-download'
import {UseAxios} from '../../../../modules/auth/core/useAxios'
import {DownloadedFilename} from '../../../../../_metronic/helpers/dowloadedFilename'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`
const api = UseAxios()

//

const downloadFile = (filename: string) => {
  return api
    .post(`/file/download/`, {filename}, {responseType: 'blob'})
    .then((res) => {
      FileDownload(res.data, DownloadedFilename(filename))
    })
    .catch((err) => console.log('download error....', err.response))
}

const getBL = (query: string | undefined) => {
  return api
    .get(`/bl?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => {
      const data = d.data
      console.log(data)

      return {
        data: data.bl,
        shp: {shipment: data.shipment_number, vessel: data.vessel_name},
        payload: data.payload,
        allBl: data.allBl,
      }
    })
    .catch((err: AxiosError) => {
      return err.response?.data
    })
}

const getBLById = (id?: ID | string | undefined) => {
  return api
    .get(`/bl/${id}`)
    .then((response: AxiosResponse<UsersQueryResponse>) => {
      const data = response.data
      return {data: data.bl, shp: {shipment: data.shipment_number, vessel: data.vessel_name}}
    })
    .catch((err: AxiosError) => {
      return err.response?.data
    })
  // .then((response: Response<BL>) => {
  //   return response.data
  // })
}
const addCheckerManually = (blId: any, checker: string): Promise<BL | undefined> => {
  return api.patch(`/bl/${blId}/checker`, {checker}).then((response: Response<BL>) => response.data)
  // .then((response: Response<BL>) => response.data)
}

const createBL = (bl: any, query?: string): Promise<BL | undefined> => {
  return api
    .post(`/bl/${query}`, bl)
    .then((response: AxiosResponse<Response<BL>>) => response.data)
    .then((response: Response<BL>) => response.data)
}

const updateBL = (bl: any): Promise<BL | undefined> => {
  return api.patch(`/bl/`, bl).then((response: Response<BL>) => response.data)
  // .then((response: Response<BL>) => response.data)
}

const deleteBL = (blId: ID): Promise<void> => {
  return api.delete(`/bl/${blId}`).then(() => {})
}

const deleteSelectedBL = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getBL,
  deleteBL,
  deleteSelectedBL,
  getBLById,
  createBL,
  updateBL,
  downloadFile,
  addCheckerManually,
}
