import {AxiosResponse} from 'axios'
import {UseAxios} from '../../../app/modules/auth/core/useAxios'
import {User} from '../../../app/modules/auth'
import {ID} from '../../helpers'

const api = UseAxios()

export const getNotification = (data: User): Promise<any[]> => {
  const {role, _id, created_at} = data

  return api
    .get(`/notif?user_role=${role}&user_created_at=${created_at}&user_id=${_id}`)
    .then((response: AxiosResponse<any[]>) => response.data)
}

export const isReadAction = (user_id: ID, notif_id: string): Promise<any[]> => {
  return api
    .patch(`/notif`, {user_id, notif_id})
    .then((response: AxiosResponse<any[]>) => response.data)
}
