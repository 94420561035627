import {FC, useState} from 'react'
import {useMutation} from 'react-query'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import ViewPDF from '../../../../../../_metronic/helpers/components/VIewPDF/ViewPDF'
import {DownloadedFilename} from '../../../../../../_metronic/helpers/dowloadedFilename'
import {stringLimit} from '../../../../../../_metronic/helpers/stringLimit'
import {ProjectOwnerPermision} from '../../../../../../_metronic/helpers/userAccess'
import {PPJK} from '../../../../../company/ppjk/ppjk-list/core/_models'
import {useAuth} from '../../../../../modules/auth'
import {useQueryResponseData} from '../../bl-detail/BlDetailQueryResponse'
import {downloadFile} from '../../core/_requests'

type Props = {
  filesnames: {filename: string; link: string}[]
  company: PPJK
}

const BlFilesCell: FC<Props> = ({filesnames, company}) => {
  const {currentUser} = useAuth()
  const dataBl = useQueryResponseData()
  const {isLoading, mutate} = useMutation(downloadFile)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [pdfFile, setPdfFile] = useState<string>()
  const [pdfName, setPdfName] = useState<string>()

  const closeViewPDF = () => {
    setOpenModal(false)
  }
  const openViewPDF = (pdf_file: string, pdf_name: string) => {
    setOpenModal(true)
    setPdfFile(pdf_file)
    setPdfName(DownloadedFilename(pdf_name))
  }

  const download = async (filename: string) => {
    await mutate(filename)
  }

  let pdfNameComponent = (file: any) => (
    <span data-dz-name className='px-2' style={{flexGrow: 2, fontSize: '.9rem'}}>
      <>{stringLimit(DownloadedFilename(file.filename), 30)}</>
    </span>
  )

  if (ProjectOwnerPermision(currentUser, company._id)) {
    pdfNameComponent = (file: any) => (
      <span
        data-dz-name
        className='px-2'
        style={{flexGrow: 2, fontSize: '.9rem', cursor: 'pointer'}}
        onClick={() => openViewPDF(file.link, file.filename)}
      >
        <>{stringLimit(DownloadedFilename(file.filename), 30)}</>
      </span>
    )
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column' style={{minWidth: '200px'}}>
        {filesnames.length > 0 &&
          filesnames.map((file) => (
            <div
              className='d-flex flex-center justify-content-start m-1 p-1'
              style={{color: '#eea47f'}}
              key={file.filename}
            >
              <img className='w-15px' src={toAbsoluteUrl('/media/svg/files/pdf.svg')} />
              {/* File Name Begins Component */}
              {pdfNameComponent(file)}
              {/* File Name Ends Component */}

              {/* Download Button Begins */}
              {isLoading ? (
                <div className='spinner-border spinner-border-sm text-danger' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : ProjectOwnerPermision(currentUser, company._id) ? (
                <i
                  className='bi bi-arrow-down-square'
                  style={{color: '#eea47f', cursor: 'pointer'}}
                  onClick={() => download(file.filename)}
                />
              ) : null}
              {/* Download Button Ends */}
            </div>
          ))}
      </div>

      {/* View PDF COmponent begins */}
      {openModal && (
        <ViewPDF
          show={openModal}
          handleClose={closeViewPDF}
          pdf_file={pdfFile}
          pdf_name={pdfName}
        />
      )}
      {/* View PDF COmponent Ends */}
    </div>
  )
}

export {BlFilesCell}
