import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BlDetailPage from '../pages/BillOfLading/BlDetailPage'
import {useAuth} from '../modules/auth'
import {AdminPermission, HoPermission} from '../../_metronic/helpers/userAccess'
import AccountPage from '../modules/accounts/AccountPage'
import CheckerPage from '../checker/Page'

const PrivateRoutes = () => {
  const ShipmentPage = lazy(() => import('../pages/shipment/ShipmentPage'))
  const AccountPageDetail = lazy(
    () => import('../modules/apps/user-management/user-details/AccountPage')
  )
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const BlPage = lazy(() => import('../pages/BillOfLading/BlPage'))
  const AllBlPage = lazy(() => import('../pages/BillOfLading/AllBlPage'))
  const PpjkPage = lazy(() => import('../company/ppjk/PpjkPage'))
  const InviteUserPage = lazy(() => import('../tools/invitation-link/InviteUserPage'))
  const {currentUser} = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/doc/pages/shipment' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='doc/pages/bl/*'
          element={
            <SuspensedView>
              <AllBlPage />
            </SuspensedView>
          }
        />
        <Route
          path='doc/pages/shipment/*'
          element={
            <SuspensedView>
              <ShipmentPage />
            </SuspensedView>
          }
        />
        <Route path='doc/pages/shipment/*'>
          <Route
            path=':id/*'
            element={
              <SuspensedView>
                <BlPage />
              </SuspensedView>
            }
          />
        </Route>
        <Route path='doc/pages/shipment/bl/*'>
          <Route
            path=':id/*'
            element={
              <SuspensedView>
                <BlDetailPage />
              </SuspensedView>
            }
          />
        </Route>
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='data/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}
        {/* ROUTE PERMISSON */}
        {AdminPermission(currentUser) ? (
          <Route
            path='tools/invite-user'
            element={
              <SuspensedView>
                <InviteUserPage />
              </SuspensedView>
            }
          />
        ) : (
          <Route path='*' element={<Navigate to='/error/404' />} />
        )}

        {/* ROUTE PERMISSON */}
        {HoPermission(currentUser) ? (
          <>
            <Route
              path='data/company/ppjk'
              element={
                <SuspensedView>
                  <PpjkPage />
                </SuspensedView>
              }
            />
            <Route
              path='data/company/checker'
              element={
                <SuspensedView>
                  <CheckerPage />
                </SuspensedView>
              }
            />
            <Route
              path='data/user-management/*'
              element={
                <SuspensedView>
                  <UsersPage />
                </SuspensedView>
              }
            />
            <Route path='/apps/user-management/user/overview/*'>
              <Route
                path=':id/*'
                element={
                  <SuspensedView>
                    <AccountPageDetail />
                  </SuspensedView>
                }
              />
            </Route>
          </>
        ) : (
          <Route path='*' element={<Navigate to='/error/404' />} />
        )}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
