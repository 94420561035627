import {FC, useContext, useEffect} from 'react'
import {ErrorHandle, WithChildren, createResponseContext, initialQueryResponse} from '../../helpers'
import {Notification} from './_models'
import {useQuery} from 'react-query'
import {getNotification} from './_request'
import {useAuth} from '../../../app/modules/auth'

const QueryResponseContext = createResponseContext<Notification>(initialQueryResponse)
const NotificationResponseProvider: FC<WithChildren> = ({children}) => {
  const {currentUser} = useAuth()

  const {
    data: responseNotif,
    refetch,
    isLoading,
  } = useQuery(
    `Notif`,
    () => {
      return currentUser && getNotification(currentUser)
    },
    {enabled: Boolean(currentUser), refetchOnWindowFocus: true, refetchInterval: 10000}
  )

  return (
    <QueryResponseContext.Provider value={{responseNotif, isLoading, refetch}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const NotificationResponse = () => useContext(QueryResponseContext)
const useNotificationResponse = () => {
  const {responseNotif} = NotificationResponse()
  if (responseNotif) {
    return responseNotif
  }

  return []
}

export {NotificationResponseProvider, useNotificationResponse}
