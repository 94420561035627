import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {User} from '../../../../../../app/modules/apps/user-management/users-list/core/_models'
import {updateUser} from '../../../../apps/user-management/users-list/core/_requests'
import {useMutation, useQueryClient} from 'react-query'
import {getUserById} from '../../../../auth/core/_requests'

const profileDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string(),
  phone: Yup.string(),
  // company: Yup.string().required('Company name is required'),
  // companySite: Yup.string().required('Company site is required'),
  // country: Yup.string().required('Country is required'),
  // language: Yup.string().required('Language is required'),
  // timeZone: Yup.string().required('Time zone is required'),
  // currency: Yup.string().required('Currency is required'),
})

type Props = {
  users?: User
}

const ProfileDetails: React.FC<Props> = ({users}) => {
  const [img, setImg] = useState<any>({new: null, old: null})
  const [loading, setLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState<Boolean>(false)
  const [error, setError] = useState()
  const queryClient = useQueryClient()

  const iniUsers: any = {}

  if (users) {
    for (const [key, value] of Object.entries(users)) {
      iniUsers[key] = value
    }
  }

  const refetchDataUser = useMutation(() => getUserById(users?._id), {
    // 💡 response of the mutation is passed to onSuccess

    onSuccess: () => {
      // ✅ update detail view directly
      console.log('successs UPDATE')
      queryClient.invalidateQueries(`user-${users?._id}`)
    },
  })

  const formik = useFormik<User>({
    enableReinitialize: true,
    initialValues: iniUsers,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      const formData = new FormData()

      for (const [key, value] of Object.entries(values)) {
        formData.append(key, value)
      }

      try {
        await updateUser(formData, values._id)
        await refetchDataUser.mutateAsync()
        setIsSuccess(true)
      } catch (error: any) {
        console.log(error)
        setLoading(false)
        setError(error)
      } finally {
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    let mount = true
    console.log('rendreing...')

    mount && setImg(users?.avatar !== 'null' && {old: users?.avatar.link})
    return () => {
      mount = false
    }
  }, [users])

  useEffect(() => {
    let mount = true
    mount && formik.setFieldValue('avatar', img.new)
    // mount && setImg({old: currentUser?.avatar.link})
    return () => {
      mount = false
    }
  }, [img])

  const imageFile = (e: any) => {
    setImg({new: e.target.files[0]})
  }

  let previewImg = toAbsoluteUrl('/media/avatars/blank.png')

  if (img) {
    previewImg = img.old ? img.old : img.new && URL.createObjectURL(img.new)
    console.log('FORMAIK', img)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>
      {isSuccess && (
        <div className='mx-10 alert alert-success' role='alert'>
          Your profil successfully updated
        </div>
      )}
      {error && (
        <div className='alert alert-danger' role='alert'>
          {error}
        </div>
      )}

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`}}
                >
                  <input
                    type='file'
                    style={{
                      position: 'absolute',
                      bottom: '40%',
                      opacity: '0',
                      cursor: 'pointer',
                      left: '-20%',
                    }}
                    onChange={(e) => imageFile(e)}
                  />
                  {img ? (
                    <div
                      className='btn btn-icon btn-sm btn-active-light-danger ms-2 absolute'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                      style={{
                        position: 'absolute',
                        left: '60%',
                      }}
                      onClick={() => setImg({new: null, old: null})}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  ) : null}
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    // style={{backgroundImage: img && `url(${URL.createObjectURL(img)})`}}
                    // style={{backgroundImage: `url(${previewImg})`}}
                  >
                    <img src={previewImg} className='w-125px h-125px' />
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...formik.getFieldProps('firstName')}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.firstName}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Last name'
                      {...formik.getFieldProps('lastName')}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.lastName}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Company</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Last name'
                  disabled
                  value={users?.company?.companyName}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Contact Phone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone number'
                  {...formik.getFieldProps('phone')}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.phone}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}
