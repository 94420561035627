/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes, Outlet} from 'react-router-dom'
import {Error404} from '../errors/components/Error404'
import {SuccessVerify} from './components/SuccessVerify'

const SuccessLayout = () => {
  return <Outlet />
}

const SuccessPage = () => (
  <Routes>
    <Route element={<SuccessLayout />}>
      <Route path='*' element={<Error404 />} />
      <Route path='verify' element={<SuccessVerify />} />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
)

export {SuccessPage}
