import React from 'react'
import {useQuery} from 'react-query'
import {useAuth, User} from '../../../auth'
import {getUserById} from '../../../auth/core/_requests'
import {ProfileDetails} from './cards/ProfileDetails'
import {SignInMethod} from './cards/SignInMethod'
// import {ConnectedAccounts} from './cards/ConnectedAccounts'
// import {EmailPreferences} from './cards/EmailPreferences'
// import {Notifications} from './cards/Notifications'
// import {DeactivateAccount} from './cards/DeactivateAccount'

type Props = {
  users?: User
}

export function Settings({users}: Props) {
  return (
    <>
      <ProfileDetails users={users} />
      <SignInMethod users={users} />
      {/* <ConnectedAccounts />
      <EmailPreferences />
      <Notifications />
      <DeactivateAccount /> */}
    </>
  )
}
