import {FC} from 'react'
import {User} from '../../../../../modules/auth'

type Props = {
  created_by?: string
  company_name?: string
}

const CreatedByCell: FC<Props> = ({created_by, company_name}) => {
  return (
    <>
      {' '}
      {created_by && (
        // <div className='badge badge-light-success fw-bolder'>
        <div className='d-flex flex-column ' style={{fontSize: '.9rem', fontWeight: 'bolder'}}>
          <span>{created_by}</span>
          <span className='text-primary'>{company_name}</span>
        </div>
      )}
    </>
  )
}

export {CreatedByCell}
