import axios, {AxiosResponse, AxiosError} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import FileDownload from 'js-file-download'
import {UseAxios} from '../../../../modules/auth/core/useAxios'
import {DownloadedFilename} from '../../../../../_metronic/helpers/dowloadedFilename'
import {NoteProps} from './Activities'
import {statusModel} from './NoteForm'

const api = UseAxios()

const getNote = async (blId?: string) => {
  const response = await api.get(`/bl/${blId}/note-checker/`)
  const result = response.data
  return result
}

const createNote = async (blId?: string, request?: NoteProps) => {
  const response = await api.post(`/bl/${blId}/note-checker/`, {
    check_status: request?.status,
    description: request?.note,
  })
  const result = response.data
  return result
}

const updateNote = async (blId?: string, noteId?: string, request?: NoteProps) => {
  const response = await api.patch(`/bl/${blId}/note-checker/${noteId}`, {
    check_status: request?.status,
    description: request?.note,
  })
  const result = response.data
  return result
}

const getStatus = async () => {
  const response = await api.get('/status')
  const result: statusModel[] = await response.data
  return result
}

export {getNote, createNote, updateNote, getStatus}
