import {FC} from 'react'
import {Interface} from 'readline'
import {StringOptions} from 'sass'
import Styles from './Loader.module.css'

type Props = {
  top?: string
}

const Loader: FC<Props> = ({top}) => {
  const Theme = localStorage.getItem('kt_theme_mode_menu')

  let themeStyles = `${Styles.loader} ${Styles.loaderDark}`
  let background = '#1f1e2e'
  if (Theme === 'light') {
    themeStyles = `${Styles.loader} ${Styles.loaderLight}`
    background = '#fff'
  }

  const styles = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    // backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    // padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  // return <div style={{...styles, position: 'absolute', textAlign: 'center'}}>Processing...</div>
  return (
    // <div>

    <div
      style={{...styles, position: 'absolute', textAlign: 'center', backgroundColor: background}}
    >
      <span className={themeStyles} style={top ? {top} : {top: '120%'}} />
    </div>
    // {/* </div> */}
  )
}

export {Loader}
