/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {createNote, getNote, getStatus} from './note_request'
import {useAuth} from '../../../../modules/auth'
import {ROLES} from '../../../../../_metronic/helpers/roles'
import {useMutation, useQueryClient} from 'react-query'
import {useParams} from 'react-router-dom'
import * as Yup from 'yup'

export type statusModel = {
  _id: string
  name: string
}

type NoteFormActionType = {
  openNoteFormAction: () => void
}

export function NoteForm({openNoteFormAction}: NoteFormActionType) {
  const [status, setStatus] = useState<statusModel[]>()
  const {currentUser} = useAuth()
  const {id} = useParams()
  const queryClient = useQueryClient()

  useEffect(() => {
    getStatus().then((result) => {
      if (currentUser?.role == ROLES.HO) {
        const statusResult = result.filter((status) => status.name !== 'Revision Improvement')
        setStatus(statusResult)
      } else {
        const statusResult = result.filter((status) => status.name == 'Revision Improvement')
        setStatus(statusResult)
      }
    })
  }, [])

  const reFetchNote = useMutation(() => getNote(id), {
    // 💡 response of the mutation is passed to onSuccess

    onSuccess: () => {
      // ✅ update detail view directly
      console.log('successs UPDATE')
      queryClient.invalidateQueries(`bl-status-${id}`)
    },
  })

  const noteSchemaValidation = Yup.object().shape({
    status: Yup.string().required(),
  })

  const formik = useFormik({
    initialValues: {
      status: ``,
      note: '',
    },
    validationSchema: noteSchemaValidation,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      console.log(values)
      try {
        await createNote(id, values)
        await reFetchNote.mutateAsync()
        openNoteFormAction()
        resetForm()
      } catch (error: any) {
        console.log(error.response.data)
      }
    },
  })

  const cancel = () => {
    formik.resetForm()
    openNoteFormAction()
  }

  return (
    <div>
      <div
        className='menu  w-250px w-md-300px'
        data-kt-menu='true'
        style={{
          display: 'flex',
          willChange: 'transform',
          zIndex: 20,
          // position: 'fixed',
          position: 'absolute',
          // inset: '0px 0px auto auto',
          margin: '0px',
          // transform: 'translate(-99px, 334px)',
          backgroundColor: 'var(--kt-card-bg)',
          borderRadius: '0.475rem',
          flexDirection: 'column',
          boxShadow: 'var(--kt-menu-dropdown-box-shadow)',
          listStyle: 'none',
          right: '8%',
          top: 0,
        }}
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Add Note</div>
        </div>

        <div className='separator border-gray-200'></div>

        <form onSubmit={formik.handleSubmit}>
          <div className='px-7 py-5'>
            <div className='mb-10'>
              <label className='form-label fw-bold'>Status:</label>

              <div>
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  // defaultValue={'1'}
                  {...formik.getFieldProps('status')}
                >
                  <option value=''>select status</option>
                  {status?.map((stat) => (
                    <option value={stat._id} key={stat._id}>
                      {stat.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className='mb-10'>
              <label className='form-label fw-bold'>Note:</label>

              <div className='d-flex flex-column'>
                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                  <textarea
                    className='form-control'
                    rows={2}
                    {...formik.getFieldProps('note')}
                    name='note'
                  ></textarea>
                </label>
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button
                type='reset'
                className='btn btn-sm btn-light btn-active-light-primary me-2'
                data-kt-menu-dismiss='true'
                onClick={cancel}
              >
                Cancel
              </button>

              <button
                type='submit'
                className='btn btn-sm btn-primary'
                data-kt-menu-dismiss='true'
                disabled={!formik.isValid || formik.values.status.length == 0}
              >
                Apply
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
