import axios, {AxiosResponse} from 'axios'
import {AuthModel, User} from './_models'

import {UseAxios} from './useAxios'
import {ID} from '../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
export const GRACE_API = process.env.REACT_APP_GRACE_API

export const REFRESH_TOKEN = `${GRACE_API}/refreshtoken`
export const LOGIN_URL = `${GRACE_API}/login`
export const REGISTER_URL = `${GRACE_API}/register`
export const RESET_PASSWORD = `${GRACE_API}/reset-password`
export const GET_USER_BY_ID = `${GRACE_API}/user`

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const GET_USER = `${API_URL}/verify_token`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

const api = UseAxios()

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(
    LOGIN_URL,
    {
      email,
      password,
    },
    {withCredentials: true}
  )
}

// Server should return AuthModel
export function register(request: any) {
  return axios.post(REGISTER_URL, {
    email: request.email,
    firstName: request.firstname,
    lastName: request.lastname,
    password: request.password,
    passwordConfirmation: request.passwordConfirmation,
    company: request.company,
    role: request.role,
    inviteToken: request.token,
  })
}

export function sendLinkForgotPassword(email: string) {
  return axios.post(`${GRACE_API}/generate/link-change-password`, {email})
}

export function resetPassword(
  password: string,
  passwordConfirmation: string,
  changePasswordToken?: string
) {
  return axios.post(RESET_PASSWORD, {
    password,
    passwordConfirmation,
    changePasswordToken,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}
export async function refreshToken() {
  return await axios
    .get(REFRESH_TOKEN, {withCredentials: true})
    .then((res: AxiosResponse<AuthModel>) => {
      return res
      // LANJUT DISINI
    })
}

export function getUserById(user_id?: ID) {
  return api.get<User>(`/user/${user_id}`)
}

export function getUserByToken(token: string) {
  return axios.post<User>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function logoutUser() {
  return axios.delete(`${GRACE_API}/logout`, {withCredentials: true})
}
