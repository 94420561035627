import {initialQueryState} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'

type Props = {
  tableName: string
}

const ShowPage = ({tableName}: Props) => {
  const {updateState} = useQueryRequest()
  const onChangeHandler = (num: string) => {
    const pageViewValidation = ['20', '50', '75', '100']

    if (!pageViewValidation.includes(num)) {
      console.log('masok KENE JEBULE')
      localStorage.setItem(tableName, '20')
      updateState({...initialQueryState, items_per_page: 20})
    } else {
      localStorage.setItem(tableName, num)
      const numb: any = num
      updateState({...initialQueryState, items_per_page: numb})
    }
  }
  const defValue = localStorage.getItem(tableName)

  return (
    <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start my-5'>
      <span className='text-gray-600 fw-bold '>Show</span>
      <div>
        <select
          className='form-select form-select-solid mx-3'
          data-kt-select2='true'
          data-placeholder='Select option'
          data-allow-clear='true'
          defaultValue={defValue || '20'}
          onChange={(e) => onChangeHandler(e.target.value)}
        >
          <option value='20'>20</option>
          <option value='50'>50</option>
          <option value='75'>75</option>
          <option value='100'>100</option>
        </select>
      </div>
    </div>
  )
}

export {ShowPage}
