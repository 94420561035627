import axios, {AxiosResponse} from 'axios'
import {colorLighten} from '../../../../_metronic/assets/ts/_utils'
import {ID, Response, ResponsePPJK} from '../../../../_metronic/helpers'
import {UseAxios} from '../../../modules/auth/core/useAxios'
import {PPJK, PpjkQueryResponse, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const api = UseAxios()

const getPpjkAndUser = () => {
  return api.get(`/checker/ppjk/user`).then((response) => {
    console.log(response)
    return response
  })
}

const getPpjk = (query: string): Promise<UsersQueryResponse> => {
  return api.get(`/ppjk?${query}`).then((d: AxiosResponse<UsersQueryResponse>) => {
    const data = d.data
    console.log(data)

    return data
  })
}

const addChecker = (ppjk_id: string, checkerId: string) => {
  return api.patch(`/ppjk/${ppjk_id}/checker/${checkerId}`).then((response) => {
    console.log(response)
    return response
  })
}

const removeChecker = (ppjk_id: string, checkerId: string) => {
  return api.delete(`/ppjk/${ppjk_id}/checker/${checkerId}`).then((response) => {
    console.log(response)
    return response
  })
}

const getPpjkById = (id?: ID | string | undefined) => {
  return api.get(`/ppjk/${id}`).then((response: AxiosResponse<PpjkQueryResponse>) => {
    const data = response.data
    return data
  })
}

const createPpjk = (company: PPJK): Promise<PPJK | undefined> => {
  return api
    .post(`/ppjk`, company)
    .then((response: AxiosResponse<ResponsePPJK<PPJK>>) => response.data)
    .then((response: ResponsePPJK<PPJK>) => response.data)
}

const updatePpjk = (company: PPJK): Promise<PPJK | undefined> => {
  return api
    .patch(`/ppjk/${company._id}`, company)
    .then((response: AxiosResponse<Response<PPJK>>) => response.data)
    .then((response: Response<PPJK>) => response.data)
}

const deletePpjk = (companyId: ID): Promise<void> => {
  return api.delete(`/ppjk/${companyId}`).then(() => {})
}

// const deleteSelectedShipment = (userIds: Array<ID>): Promise<void> => {
//   const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
//   return axios.all(requests).then(() => {})
// }

export {
  getPpjkAndUser,
  addChecker,
  removeChecker,
  getPpjk,
  deletePpjk,
  updatePpjk,
  createPpjk,
  getPpjkById,
}
