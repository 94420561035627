import {ID, KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useParams} from 'react-router-dom'
import {useQueryResponseData} from '../../bl-detail/BlDetailQueryResponse'

const BlDetailToolbar = () => {
  const id: any = useParams().id
  const {setItemIdForUpdate} = useListView()

  const openAddUserModal = (id: number) => {
    setItemIdForUpdate(id)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <ShipmentListFilter /> */}

      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={() => openAddUserModal(id)}>
        <i className='bi bi-pencil-square mx-2' />
        Edit
      </button>
      {/* end::Add user */}
    </div>
  )
}

export {BlDetailToolbar}
