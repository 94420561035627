import {FC} from 'react'
import {ExpiredCheck} from '../../../../../../_metronic/helpers/time/ExpiredCheck'
import {timeSince} from '../../../../../../_metronic/helpers/time/timeCount'

type Props = {
  last_update?: string
}

const BlLastUpdateCell: FC<Props> = ({last_update}) => {
  // console.log(ExpiredCheck(last_update))
  ExpiredCheck(last_update)

  return <div className='fs-7 fw-bolder'>{timeSince(last_update)}</div>
}

export {BlLastUpdateCell}
