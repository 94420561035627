import React, {useEffect} from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Overview} from './components/Overview'
import {Settings} from './components/settings/Settings'
import {AccountHeader} from './AccountHeader'
import {useQuery} from 'react-query'
import {useAuth, User} from '../auth'
import {getUserById} from '../auth/core/_requests'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Account',
    path: '/crafted/account/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AccountPage: React.FC = () => {
  console.log(Outlet)
  const {currentUser, setCurrentUser} = useAuth()
  const {data, isLoading} = useQuery(
    `user-${currentUser?._id}`,
    () => {
      return currentUser && getUserById(currentUser?._id)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: true}
  )
  const dataUsers = data?.data

  console.log(isLoading)

  useEffect(() => {
    let mount = true
    if (data && mount) {
      console.log('CEK RENDER')

      setCurrentUser(dataUsers)
    }

    mount = false
    // return () => (mount = false)
  }, [data])

  return (
    <Routes>
      <Route
        element={
          <>
            <AccountHeader users={dataUsers} />
            <Outlet />
          </>
        }
      >
        <Route
          path='overview'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
              <Overview users={dataUsers} />
            </>
          }
        />
        <Route
          path='settings'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle>
              <Settings users={dataUsers} />
            </>
          }
        />
        <Route index element={<Navigate to='/crafted/account/overview' />} />
      </Route>
    </Routes>
  )
}

export default AccountPage
