import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, User} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserById, logoutUser} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import jwt_decode from 'jwt-decode'
import {DecodeModel} from '..'
import {getNotification} from '../../../../_metronic/partials/notification/_request'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  authMessage?: {message: string; email: string}
  saveAuthMessage: (auth: {message: string; email: string} | undefined) => void
  currentUser: User | undefined
  setCurrentUser: Dispatch<SetStateAction<User | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  authMessage: undefined,
  saveAuthMessage: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => useContext(AuthContext)

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [authMessage, setAuthMessage] = useState<{message: string; email: string} | undefined>()
  const [currentUser, setCurrentUser] = useState<User | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const saveAuthMessage = (auth: {message: string; email: string} | undefined) => {
    console.log(auth)

    setAuthMessage(auth)
  }

  const logout = async () => {
    await logoutUser()
    saveAuthMessage(undefined)
    setCurrentUser(undefined)
    saveAuth(undefined)
  }

  return (
    <AuthContext.Provider
      value={{auth, authMessage, saveAuth, saveAuthMessage, currentUser, setCurrentUser, logout}}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const requestUser = async (access_token: string) => {
    try {
      if (!didRequest.current) {
        const decoded: DecodeModel = jwt_decode(access_token)
        const {data} = await getUserById(decoded.user_id)
        console.log('KENEBOS', data)

        if (data) {
          setCurrentUser(data)

          // LOAD NOTIFICATION
          getNotification(data)
        }
        if (data.status == 'inactive') {
          logout()
        }
      }
    } catch (error) {
      console.error(error)
      if (!didRequest.current) {
        logout()
      }
    } finally {
      setShowSplashScreen(false)
    }

    return () => (didRequest.current = true)
  }

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    if (auth?.accessToken) {
      requestUser(auth.accessToken)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth, AuthContext}
