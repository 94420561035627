import React from 'react'
import axios, {AxiosRequestConfig} from 'axios'
import {getAuth} from './AuthHelpers'
import {refreshToken} from './_requests'
import {DecodeModel} from './_models'
import jwt_decode from 'jwt-decode'

const AxioAxios: React.FC = () => {
  return <div></div>
}

const UseAxios = () => {
  const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
  const auth = getAuth()
  const GRACE_API = process.env.REACT_APP_GRACE_API
  const BASE_URL = process.env.BASE_URL

  // return (<div></div>)
  const axioInstance = axios.create({
    baseURL: GRACE_API,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${auth?.accessToken}`,
    },
  })

  const onRequest = async (config: AxiosRequestConfig) => {
    const currentDate = new Date()
    const auth = getAuth()
    const decoded: DecodeModel | undefined = auth && (await jwt_decode(auth.accessToken))

    if (auth && decoded && decoded.exp * 1000 < currentDate.getTime()) {
      try {
        const response = await refreshToken()

        const ST_value = {accessToken: response.data.accessToken}

        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(ST_value))

        config.headers!.Authorization = `Bearer ${response.data.accessToken}`
      } catch (error) {
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
        window.location.href = `${BASE_URL}/admin/auth`
      }
    } else {
      config.headers!.Authorization = `Bearer ${auth?.accessToken}`
    }
    return config
  }

  axioInstance.interceptors.request.use(onRequest, (err: any) => Promise.reject(err))
  return axioInstance
}

export {AxioAxios, UseAxios}
