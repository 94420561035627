// import 'antd/dist/antd.css'
import dayjs from 'dayjs'
import {ConfigProvider, DatePicker, Space} from 'antd'
import {dateProps} from './AllBlFilter'
const {RangePicker} = DatePicker

type Props = {
  changeDataHanlder: (date: any) => void
  datePicker: dateProps
}

const DatePickerComponent = ({changeDataHanlder, datePicker}: Props) => {
  let bgColor = '#151521'
  let date = null
  let text = '#2ba0ed'
  const Theme = localStorage.getItem('kt_theme_mode_menu')
  if (Theme === 'light') {
    bgColor = '#ffffff'
    text = 'rgba(0, 0, 0, 0.88)'
  }

  if (datePicker.start_date && datePicker.end_date) {
    date = [dayjs(datePicker.start_date, 'YYYY/MM/DD'), dayjs(datePicker.end_date, 'YYYY/MM/DD')]
  } else {
    date = undefined
  }

  return (
    <div className='mb-4'>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: '#00b96b',
            borderRadius: 2,

            // Alias Token
            colorBgContainer: 'var(--kt-input-solid-bg)',
            colorBgElevated: bgColor,
            colorText: text,
            colorTextQuaternary: '#5f727f82',
            colorIcon: text,
            colorIconHover: '#00b96b',
            controlItemBgHover: '#94e1c1',
          },
        }}
      >
        <Space direction='vertical' size={12}>
          <RangePicker
            format='DD-MM-YYYY'
            onChange={(date) => changeDataHanlder(date)}
            defaultValue={
              datePicker.start_date
                ? [
                    dayjs(datePicker.start_date, 'YYYY/MM/DD'),
                    dayjs(datePicker.end_date, 'YYYY/MM/DD'),
                  ]
                : null
            }
          />
        </Space>
      </ConfigProvider>
    </div>
  )
}

export default DatePickerComponent
