import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../_metronic/helpers'
import {PPJK} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {ListLoading} from '../components/loading/ListLoading'
import CheckerInput from './Input'
import {addChecker} from '../core/_requests'

type Props = {
  isUserLoading: boolean
  PPJK?: PPJK
}

const editBLSchema = Yup.object().shape({
  user: Yup.string().required('Email is required'),
  company: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Company is required'),
})

const EditModalForm: FC<Props> = ({PPJK, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [userForEdit] = useState<{
    company: string
    user: string
  }>({
    company: '',
    user: '',
  })

  const [error, setError] = useState<string | undefined>(undefined)

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editBLSchema,
    onSubmit: async (values, {setSubmitting}) => {
      console.log('formik value:', values)

      setSubmitting(true)
      try {
        await addChecker(values.company, values.user)
        cancel(true)
      } catch (err: any) {
        setError(err.response.data.errors)
        console.error(err.response)
      } finally {
        setSubmitting(true)
      }
    },
  })

  console.log('formik v', formik)

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* ERROR MESSAGE */}
        {error && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{error}</div>
          </div>
        )}
        {/* ERROR MESSAGE */}
        <CheckerInput formik={formik} isUserLoading={isUserLoading} />
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <ListLoading />}
    </>
  )
}

export {EditModalForm}
