import react, {useEffect, useRef} from 'react'
import WebViewer from '@pdftron/webviewer'
import {PDFHeader} from './PDFHeader'
import {Modal} from 'react-bootstrap'

type Props = {
  show: boolean
  handleClose: () => void
  pdf_file?: string
  pdf_name?: string
}

const ViewPDF = ({show, handleClose, pdf_file, pdf_name}: Props) => {
  const viewerDiv = useRef<HTMLDivElement>(null)
  console.log(pdf_name)

  useEffect(() => {
    WebViewer(
      {
        path: '/lib',
        initialDoc: pdf_file,
      },
      viewerDiv.current as HTMLDivElement
    ).then((instance) => {
      console.log(instance)
    })
  }, [])
  return (
    <>
      <Modal
        id='kt_modal_delete_data'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-650px m-auto'
        show={show}
        onHide={handleClose}
      >
        {/* begin::Modal dialog */}
        {/* begin::Modal content */}
        <div className='modal-content'>
          <PDFHeader handleClose={handleClose} pdf_name={pdf_name} />

          {/* begin::Modal body */}
          <div
            className='modal-body scroll-y mx-5 mx-xl-15 my-7'
            style={{height: '800px'}}
            ref={viewerDiv}
          >
            {/* <div style={{height: '300px'}} ref={viewerDiv}></div> */}
          </div>
          {/* end::Modal body */}
        </div>
        {/* end::Modal content */}
        {/* </div> */}
        {/* end::Modal dialog */}
        {/* </div> */}
        {/* begin::Modal Backdrop */}
        {/* <div className='modal-backdrop fade show'></div> */}
      </Modal>
    </>
  )
}

export default ViewPDF
