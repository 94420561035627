import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {IUpdateEmail, IUpdatePassword} from '../../../../accounts/components/settings/SettingsModel'
import {UseAxios} from '../../../../auth/core/useAxios'
import {User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`
const api = UseAxios()

const getUsers = (query?: string) => {
  return api.get(`user?${query}`).then((d: AxiosResponse<UsersQueryResponse>) => {
    const data = d.data
    console.log('dddPPP', d)

    // return {}
    return {data: data.data, payload: data.payload}
  })
}

const getUserById = (id: ID) => {
  return api
    .get(`user/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response) => response)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: any, id?: ID): Promise<User | undefined> => {
  console.log('USERDATA', user)

  return api.patch(`user/${id}`, user).then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const updateEmailUser = (email?: string, dataUpdate?: IUpdateEmail) => {
  return api
    .post(`/change-email/${email}`, dataUpdate)
    .then((response: Response<User>) => response.data)
}

const updatePasswordUser = (email?: string, dataUpdate?: IUpdatePassword) => {
  return api
    .post(`/change-password/${email}`, dataUpdate)
    .then((response: Response<User>) => response.data)
}

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  updateEmailUser,
  updatePasswordUser,
}
