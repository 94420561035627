/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import DeletedModal from '../../../../../../_metronic/helpers/components/DeletedModal'
import {ROLES} from '../../../../../../_metronic/helpers/roles'
import {AdminPermission, projectOwnerCanView} from '../../../../../../_metronic/helpers/userAccess'
import {useAuth} from '../../../../../modules/auth'
import {useAllDataBlResponse} from '../../core/AllBLContextProvider'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteBL, getBL} from '../../core/_requests'
import {AddCheckerModal} from '../../bl-edit-modal/AddCheckerModal'

type Props = {
  id: ID
  company_id?: ID
  sppb_date: string
}

const BlActionsCell: FC<Props> = ({id, company_id, sppb_date}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const {queryBL} = useAllDataBlResponse()
  const queryClient = useQueryClient()
  const {currentUser} = useAuth()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openCheckerModal, setOpenCheckerModal] = useState<boolean>(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const closeCheckerModal = () => {
    setOpenCheckerModal(false)
  }

  const closeDeleteModal = () => {
    setOpenModal(false)
    setOpenCheckerModal(false)
  }

  const reFetchUpdateBl = useMutation(() => getBL(queryBL), {
    // 💡 response of the mutation is passed to onSuccess

    onSuccess: () => {
      // ✅ update detail view directly
      console.log('successs UPDATE')
      queryClient.invalidateQueries(`${QUERIES.BL_LIST}I-${queryBL}`)
    },
  })

  const deleteItem = useMutation(() => deleteBL(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: async () => {
      // ✅ update detail view directly
      console.log('successs DELETE')
      queryClient.invalidateQueries(`${QUERIES.BL_LIST}-${query}`)
      closeDeleteModal()
      await reFetchUpdateBl.mutateAsync()
    },
  })

  let dsableClasses = 'd-flex disabled btn btn-light btn-active-light-primary btn-sm'

  if (projectOwnerCanView(currentUser, company_id, sppb_date)) {
    dsableClasses = 'btn btn-light btn-active-light-primary btn-sm '
  }

  const ActionLogo = () =>
    projectOwnerCanView(currentUser, company_id, sppb_date) ? (
      <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
    ) : (
      <KTSVG path='/media/icons/duotune/lock/lock-closed-outline.svg' className='svg-icon-5 m-0' />
    )

  return (
    <>
      <a
        href='#'
        className={dsableClasses}
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <span>Actions</span>
        <ActionLogo />
      </a>
      {/* begin::Menu */}

      {/* CEK USER ROLE ACTIONS BUTTON */}

      {projectOwnerCanView(currentUser, company_id, sppb_date) && (
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openEditModal}>
              Edit
            </a>
          </div>
          {/* end::Menu item */}
          {/* begin::Menu item */}
          {AdminPermission(currentUser) && (
            <div className='menu-item px-3'>
              <a className='menu-link px-3' onClick={() => setOpenCheckerModal(true)}>
                Add Checker
              </a>
            </div>
          )}
          {/* end::Menu item */}
          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={() => setOpenModal(true)}
            >
              Delete
            </a>
          </div>
          {/* end::Menu item */}
          <DeletedModal show={openModal} handleClose={closeDeleteModal} deleteItem={deleteItem} />
          <AddCheckerModal show={openCheckerModal} handleClose={closeCheckerModal} blId={id} />
        </div>
      )}

      {/* end::Menu */}
    </>
  )
}

export {BlActionsCell}
