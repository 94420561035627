import React from 'react'
import {BL} from '../../core/_models'
import {BlFilesCell} from './BlFilesCell'

type Props = {
  dataBl: BL[]
}

const DetailRow: React.FC<Props> = ({dataBl}) => {
  const blDetail = dataBl[0]
  const company: any = blDetail.created_by_company
  console.log(company)

  return (
    <>
      <tr>
        <td>Shipping Documents</td>
        <td>{blDetail.bl_number}</td>
        <td>
          {blDetail.shipping_doc_file.length > 0 ? (
            <i className='bi bi-check-lg text-primary fa-lg mx-10'></i>
          ) : (
            <i className='bi bi-x-lg text-danger mx-10'></i>
          )}
        </td>
        <td>
          <BlFilesCell company={company} filesnames={blDetail.shipping_doc_file} />
        </td>
      </tr>

      <tr>
        <td>Bill of Lading</td>
        <td>{blDetail.bl_number}</td>
        <td>
          {blDetail.bl_file.length > 0 ? (
            <i className='bi bi-check-lg text-primary fa-lg mx-10'></i>
          ) : (
            <i className='bi bi-x-lg text-danger mx-10'></i>
          )}
        </td>
        <td>
          <BlFilesCell company={company} filesnames={blDetail.bl_file} />
        </td>
      </tr>

      <tr>
        <td>AJU / PIB</td>
        <td>{blDetail.aju_number}</td>
        <td>
          {blDetail.aju_file.length > 0 ? (
            <i className='bi bi-check-lg text-primary fa-lg mx-10'></i>
          ) : (
            <i className='bi bi-x-lg text-danger mx-10'></i>
          )}
        </td>
        <td>
          <BlFilesCell company={company} filesnames={blDetail.aju_file} />
        </td>
      </tr>

      <tr>
        <td>Invoice</td>
        <td>-</td>
        <td>
          {blDetail.invoice_file.length > 0 ? (
            <i className='bi bi-check-lg text-primary fa-lg mx-10'></i>
          ) : (
            <i className='bi bi-x-lg text-danger mx-10'></i>
          )}
        </td>
        <td>
          <BlFilesCell company={company} filesnames={blDetail.invoice_file} />
        </td>
      </tr>

      <tr>
        <td>Skep Timbun</td>
        <td>-</td>
        <td>
          {blDetail.skepTimbun_file.length > 0 ? (
            <i className='bi bi-check-lg text-primary fa-lg mx-10'></i>
          ) : (
            <i className='bi bi-x-lg text-danger mx-10'></i>
          )}
        </td>
        <td>
          <BlFilesCell company={company} filesnames={blDetail.skepTimbun_file} />
        </td>
      </tr>

      <tr>
        <td>Penjaluran</td>
        <td>-</td>
        <td>
          {blDetail.penjaluran_file.length > 0 ? (
            <i className='bi bi-check-lg text-primary fa-lg mx-10'></i>
          ) : (
            <i className='bi bi-x-lg text-danger mx-10'></i>
          )}
        </td>
        <td>
          <BlFilesCell company={company} filesnames={blDetail.penjaluran_file} />
        </td>
      </tr>

      <tr>
        <td>E-billing</td>
        <td>-</td>
        <td>
          {blDetail.e_billing_file.length > 0 ? (
            <i className='bi bi-check-lg text-primary fa-lg mx-10'></i>
          ) : (
            <i className='bi bi-x-lg text-danger mx-10'></i>
          )}
        </td>
        <td>
          <BlFilesCell company={company} filesnames={blDetail.e_billing_file} />
        </td>
      </tr>

      <tr>
        <td>Certificate of Origin</td>
        <td>-</td>
        <td>
          {blDetail.coo_file.length > 0 ? (
            <i className='bi bi-check-lg text-primary fa-lg mx-10'></i>
          ) : (
            <i className='bi bi-x-lg text-danger mx-10'></i>
          )}
        </td>
        <td>
          <BlFilesCell company={company} filesnames={blDetail.coo_file} />
        </td>
      </tr>

      <tr>
        <td>Insurance</td>
        <td>-</td>
        <td>
          {blDetail.insurance_file.length > 0 ? (
            <i className='bi bi-check-lg text-primary fa-lg mx-10'></i>
          ) : (
            <i className='bi bi-x-lg text-danger mx-10'></i>
          )}
        </td>
        <td>
          <BlFilesCell company={company} filesnames={blDetail.insurance_file} />
        </td>
      </tr>

      <tr>
        <td>BPN</td>
        <td>-</td>
        <td>
          {blDetail.bpn_file.length > 0 ? (
            <i className='bi bi-check-lg text-primary fa-lg mx-10'></i>
          ) : (
            <i className='bi bi-x-lg text-danger mx-10'></i>
          )}
        </td>
        <td>
          <BlFilesCell company={company} filesnames={blDetail.bpn_file} />
        </td>
      </tr>

      <tr>
        <td>Cargo Policy</td>
        <td>-</td>
        <td>
          {blDetail.cargo_policy_file.length > 0 ? (
            <i className='bi bi-check-lg text-primary fa-lg mx-10'></i>
          ) : (
            <i className='bi bi-x-lg text-danger mx-10'></i>
          )}
        </td>
        <td>
          <BlFilesCell company={company} filesnames={blDetail.cargo_policy_file} />
        </td>
      </tr>

      <tr>
        <td>SPPB</td>
        <td>-</td>
        <td>
          {/* FILE BELUM DI EDIT */}
          {blDetail.sppb_file.length > 0 ? (
            <i className='bi bi-check-lg text-primary fa-lg mx-10'></i>
          ) : (
            <i className='bi bi-x-lg text-danger mx-10'></i>
          )}
        </td>
        <td>
          <BlFilesCell company={company} filesnames={blDetail?.sppb_file} />
        </td>
      </tr>

      <tr>
        <td>Notul</td>
        <td>-</td>
        <td>
          {blDetail.notul_file.length > 0 ? (
            <i className='bi bi-check-lg text-primary fa-lg mx-10'></i>
          ) : (
            <i className='bi bi-x-lg text-danger mx-10'></i>
          )}
        </td>
        <td>
          <BlFilesCell company={company} filesnames={blDetail.notul_file} />
        </td>
      </tr>

      <tr>
        <td>Others</td>
        <td>-</td>
        <td>
          {blDetail.others_file.length > 0 ? (
            <i className='bi bi-check-lg text-primary fa-lg mx-10'></i>
          ) : (
            <i className='bi bi-x-lg text-danger mx-10'></i>
          )}
        </td>
        <td>
          <BlFilesCell company={company} filesnames={blDetail.others_file} />
        </td>
      </tr>
    </>
  )
}

export default DetailRow
