/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {Link, Route} from 'react-router-dom'

type Props = {
  bl: {
    bl_number: string
    _id: number
  }
}

const BlInfoCell: FC<Props> = ({bl}) => {
  return (
    <Link to={'/doc/pages/shipment/bl/' + bl._id} className='text-gray-800 text-hover-primary mb-1'>
      {bl.bl_number.toUpperCase()}
    </Link>
  )
}

export {BlInfoCell}
