// @ts-nocheck
import {Column} from 'react-table'
import {BlInfoCell} from './BlInfoCell'
import {BlCustomHeader} from './BlCustomHeader'
import {BL} from '../../core/_models'

const BlColumnsDetail: ReadonlyArray<Column<BL>> = [
  {
    Header: (props) => (
      <BlCustomHeader tableProps={props} title='Doc Name' className='min-w-200px' />
    ),
    id: 'bl',
    Cell: ({...props}) => <BlInfoCell bl_number='Bll of Lading' />,
  },
  {
    Header: (props) => (
      <BlCustomHeader tableProps={props} title='Doc Number' className='min-w-125px' />
    ),
    accessor: 'bl_number',
  },
  {
    Header: (props) => (
      <BlCustomHeader tableProps={props} title='Doc Checklist' className='min-w-125px' />
    ),
    id: 'last_update',
    // Cell: ({...props}) => (
    //   <BlLastLoginCell last_login={props.data[props.row.index].bl_doc.last_update} />
    // ),
  },
  {
    Header: (props) => (
      <BlCustomHeader tableProps={props} title='Doc File' className='min-w-125px' />
    ),
    accessor: 'bl_file',
    // Cell: ({...props}) => <BlFilesCell filesnames={props.data[props.row.index].bl_file} />,
  },
  //   {
  //     Header: (props) => (
  //       <BlCustomHeader tableProps={props} title='Created' className='min-w-125px' />
  //     ),
  //     accessor: 'bl_doc.created',
  //   },
  //   {
  //     Header: (props) => (
  //       <BlCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //     ),
  //     id: 'actions',
  //     Cell: ({...props}) => <BlActionsCell id={props.data[props.row.index].bl_doc._id} />,
  //   },
]

export {BlColumnsDetail}
