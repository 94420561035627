import {useMemo} from 'react'
import {useTable, ColumnInstance} from 'react-table'
import {CustomHeaderColumn} from '../table/columns/CustomHeaderColumn'
import {BlColumnsDetail} from '../table/columns/_columnsDetail'
import {BL} from '../core/_models'
import {Loader} from '../../../../../_metronic/helpers/components/loading/Loader'
// import {ShipmentListPagination} from '../components/pagination/ListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'
import DetailRow from '../table/columns/DetailRow'
import {useQueryResponseData, useQueryResponseLoading} from './BlDetailQueryResponse'
import {NoPageFound} from '../../../../modules/errors/components/NoPageFound'
import {BlDetailHeader} from '../components/header/BlDetailHeader'
import {Activities} from './Activities'

type Props = {}

const BlDetailTable = () => {
  const dataBl = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => dataBl, [dataBl])
  const columns = useMemo(() => BlColumnsDetail, [])
  const {getTableProps, getTableBodyProps, headers} = useTable({
    columns,
    data,
  })
  // setItemIdForUpdate(bl[0]._id)

  console.log(dataBl)

  if (dataBl[0]?.name || dataBl[0]?.errors) return <NoPageFound />

  return (
    <>
      <BlDetailHeader />
      <Activities className='mb-5 mb-xxl-8' />
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<BL>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {data.length > 0 ? (
                <DetailRow dataBl={data} />
              ) : (
                isLoading || (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        {/* <ShipmentListPagination /> */}
        {isLoading && <Loader top='120%' />}
      </KTCardBody>
    </>
  )
}

export {BlDetailTable}
