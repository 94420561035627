/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {getNote, getStatus, updateNote} from './note_request'
import {useAuth} from '../../../../modules/auth'
import {ROLES} from '../../../../../_metronic/helpers/roles'
import {useParams} from 'react-router-dom'
import {useMutation, useQueryClient} from 'react-query'

export type statusModel = {
  _id: string
  name: string
}

export function EditNoteForm({openNoteFormAction, activity}: any) {
  const [status, setStatus] = useState<statusModel[]>()
  const {currentUser} = useAuth()
  const {id} = useParams()
  const queryClient = useQueryClient()

  const noteForEdit = {
    status: activity.check_status._id,
    note: activity.description,
  }

  useEffect(() => {
    getStatus().then((result) => {
      if (currentUser?.role == ROLES.HO || currentUser?.role == ROLES.ADMIN) {
        const statusResult = result.filter((status) => status.name !== 'Revision Improvement')
        setStatus(statusResult)
      } else {
        const statusResult = result.filter((status) => status.name == 'Revision Improvement')
        setStatus(statusResult)
      }
    })
  }, [])

  const reFetchNote = useMutation(() => getNote(id), {
    // 💡 response of the mutation is passed to onSuccess

    onSuccess: () => {
      // ✅ update detail view directly
      console.log('successs UPDATE')
      queryClient.invalidateQueries(`bl-status-${id}`)
    },
  })

  const formik = useFormik({
    initialValues: noteForEdit,
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      console.log(values)
      try {
        await updateNote(id, activity._id, values)
        await reFetchNote.mutateAsync()
        openNoteFormAction()
        resetForm()
      } catch (error: any) {
        console.log(error.response.data)
      }
    },
  })

  const cancel = () => {
    formik.resetForm()
    openNoteFormAction()
  }

  console.log(id)

  return (
    <div
      className='menu  w-250px w-md-300px'
      data-kt-menu='true'
      style={{
        display: 'flex',
        willChange: 'transform',
        zIndex: 105,
        position: 'fixed',
        inset: '0px 0px auto auto',
        margin: '0px',
        transform: 'translate(-436px, 334px)',
        backgroundColor: 'var(--kt-card-bg)',
        borderRadius: '0.475rem',
        flexDirection: 'column',
        boxShadow: 'var(--kt-menu-dropdown-box-shadow)',
        listStyle: 'none',
      }}
    >
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Edit Note</div>
      </div>

      <div className='separator border-gray-200'></div>

      <form onSubmit={formik.handleSubmit}>
        <div className='px-7 py-5'>
          <div className='mb-10'>
            <label className='form-label fw-bold'>Status:</label>

            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                // defaultValue={'1'}
                {...formik.getFieldProps('status')}
              >
                {status?.map((stat) => (
                  <option value={stat._id} key={stat._id}>
                    {stat.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className='mb-10'>
            <label className='form-label fw-bold'>Note:</label>

            <div className='d-flex flex-column'>
              <label className='form-check form-check-sm form-check-custom form-check-solid'>
                <textarea
                  className='form-control'
                  rows={2}
                  {...formik.getFieldProps('note')}
                  name='note'
                ></textarea>
              </label>
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-light btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={cancel}
            >
              Cancel
            </button>

            <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
              Apply
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
