import * as React from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
} from '../../../../../_metronic/helpers'
import {useQueryRequest} from './QueryRequestProvider'
import {BL} from './_models'
import {getBL} from './_requests'

export interface IAllBLContextProviderProps {
  children: React.ReactNode
}

const AllBLContext = createResponseContext<BL | undefined>(initialQueryResponse)
const AllBLContextProvider = ({children}: IAllBLContextProviderProps) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = React.useState<string>(stringifyRequestQuery(state))

  const recordPerPage: any = localStorage.getItem('show-all-bl-record')

  const updatedQuery = React.useMemo(
    () => stringifyRequestQuery({...state, items_per_page: recordPerPage}),
    [state]
  )

  React.useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])
  const {
    data: response,
    data: responseBl,
    isFetching,
    refetch,
  } = useQuery(
    `${QUERIES.BL_LIST}-${query}`,
    () => {
      return getBL(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: true}
  )
  console.log('QUERY TEST', updatedQuery)

  return (
    <AllBLContext.Provider
      value={{response, responseBl, isLoading: isFetching, refetch, queryBL: query}}
    >
      {children}
    </AllBLContext.Provider>
  )
}

const useAllDataBlResponse = () => React.useContext(AllBLContext)

//RESPONSE WITHOUT QUERY
const useQueryResponseAllDataBl = () => {
  const {responseBl} = useAllDataBlResponse()
  if (!responseBl?.allBl) {
    return []
  }
  return responseBl?.allBl || []
}

//RESPONSE WITH QUERY
const AllDataBlResponse = () => {
  const {response} = useAllDataBlResponse()
  if (response?.name == 'CastError') {
    const data = new Array()
    data.push(response)

    return data || []
  }
  if (!response?.data) {
    return []
  }
  return response?.data || []
}

const BlResponseLoading = (): boolean => {
  const {isLoading} = useAllDataBlResponse()
  return isLoading
}

const useQueryResponsePagination = () => {
  const recordPerPage: any = localStorage.getItem('all_bl_show')
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
    // page: 1,
    // items_per_page: recordPerPage || 3,
    // last_page: 0,
  }

  const {response} = useAllDataBlResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

export {
  useQueryResponseAllDataBl,
  AllBLContextProvider,
  AllDataBlResponse,
  useAllDataBlResponse,
  BlResponseLoading,
  useQueryResponsePagination,
}
