import BackButton from '../../../../../../_metronic/helpers/components/BackButton'
import {ROLES} from '../../../../../../_metronic/helpers/roles'
import {timeSince} from '../../../../../../_metronic/helpers/time/timeCount'
import {projectOwnerCanView} from '../../../../../../_metronic/helpers/userAccess'
import {ListsWidget5} from '../../../../../../_metronic/partials/widgets'
import {useAuth} from '../../../../../modules/auth'
import {useQueryResponseTittle, useQueryResponseData} from '../../bl-detail/BlDetailQueryResponse'
import {useListView} from '../../core/ListViewProvider'
import {BlTrackCell} from '../../table/columns/BlTrackCell'
import {BlDetailToolbar} from './BlDetailToolbar'

const BlDetailHeader = () => {
  const {currentUser} = useAuth()
  const dataBl = useQueryResponseData()
  const BL = dataBl[0]
  const shp: any = useQueryResponseTittle()
  let color

  switch (BL?.track) {
    case 'red':
      color = 'text-danger'
      break
    case 'green':
      color = 'text-success'
      break
    case 'yellow':
      color = 'text-warning'
      break

    default:
      break
  }

  return (
    <>
      <BackButton />

      <div className='card-header align-items-center border-0 pt-6'>
        <div>
          <div className='text-gray-800 fs-2 fw-bolder me-1'>{shp?.shipment?.toUpperCase()}</div>
          <div className='badge badge-light-success  fs-6 fw-bolder me-1 mb-5'>
            {shp?.vessel?.toUpperCase()}
          </div>
          <div>
            {/* <p className='mb-0 ' style={{color: '#6d6d80'}}>
              <span className='text-gray-600 fw-semibold'>Status : </span>
              <span className='fw-bolder'>{BL?.status.toUpperCase()} </span>
            </p>
            <p className='mb-0' style={{color: '#6d6d80'}}>
              <span className='text-gray-600 fw-semibold'>Track&nbsp;&nbsp; :{` `}</span>
              <span className={`fw-bolder ${color}`}>
                {BL?.track.charAt(0).toUpperCase() + BL?.track.slice(1)}
              </span>
            </p> */}
            {/* <p style={{color: '#6d6d80', fontStyle: 'italic', paddingTop: '10px'}}>
              Last Update {timeSince(BL?.last_update)}
            </p> */}
          </div>

          {/* <ListsWidget5 className='mb-5 mb-xxl-8' /> */}
        </div>
        {/* <BlListSearchComponent /> */}
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          {/* begin::Group actions */}
          {projectOwnerCanView(currentUser, BL?.created_by_company._id, BL?.sppb_upload_at) ? (
            <BlDetailToolbar />
          ) : null}
          {/* end::Group actions */}
        </div>
        {/* end::Card toolbar */}
      </div>
    </>
  )
}

export {BlDetailHeader}
