/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {NoteForm} from './NoteForm'
import {useParams} from 'react-router-dom'
import {getNote} from './note_request'
import {useQuery} from 'react-query'
import {ActivityTimeCounter} from '../../../../../_metronic/helpers/time/CountDocByTime'
import {useAuth} from '../../../../modules/auth'
import {useQueryResponseData} from './BlDetailQueryResponse'
import {EditNotePermision, NotePermision} from '../../../../../_metronic/helpers/userAccess'
import {EditNoteForm} from './EditNoteForm'

type Props = {
  className: string
}

export type NoteProps = {
  status?: string
  note?: string
}

const Activities: React.FC<Props> = ({className}) => {
  const dataBl = useQueryResponseData()
  const BL = dataBl[0]
  const {id} = useParams()
  const {currentUser} = useAuth()
  const [openActivity, setOpenActivity] = useState<boolean>(true)
  const [openNoteForm, setOpenNoteForm] = useState<boolean>(false)
  const [openEditNoteForm, setOpenEditNoteForm] = useState<boolean>(false)
  const [activity, setActivity] = useState<any>()

  const openActivityAction = (e: any) => {
    e.preventDefault()
    setOpenActivity((prev) => !prev)
    e.currentTarget.classList.toggle('active')
  }

  const openNoteFormAction = () => {
    setOpenNoteForm((prev) => !prev)
  }

  const openEditNoteFormAction = (dataActivity: any) => {
    setOpenEditNoteForm((prev) => !prev)
    setActivity(dataActivity)
  }

  const {
    data: activities,
    isFetching,
    refetch,
  } = useQuery(
    [`bl-status-${id}`],
    () => {
      // setItemIdForUpdate(id)
      return getNote(id)
    },
    {cacheTime: 0}
  )

  const color = (status?: string) => {
    switch (status) {
      case 'Pending':
        return 'text-warning'

      case 'Revision':
        return 'text-danger'

      case 'Revision Improvement':
        return 'text-primary'

      case 'Approved':
        return 'text-success'

      default:
        return 'text-primary'
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title d-flex flex-column'>
          <span
            className='fw-bold mb-2 text-dark mx-4 rotate btn btn-icon mx-5'
            data-kt-rotate='true'
            onClick={(e) => openActivityAction(e)}
          >
            Activities
            <KTSVG
              path='/media/icons/duotune/arrows/arr072.svg'
              className='svg-icon-5 ms-3 rotate-270 '
            />
          </span>
          {/* <span className='text-muted fw-semibold fs-7'>890,344 Sales</span> */}
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          {openActivity &&
            NotePermision(currentUser, BL?.created_by_company?._id, BL?.checker?._id) && (
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                onClick={openNoteFormAction}
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                title='Add Note'
              >
                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
              </button>
            )}
          {/* end::Menu */}
        </div>
      </div>
      {openNoteForm && <NoteForm openNoteFormAction={openNoteFormAction} />}
      {/* end::Header */}
      {/* begin::Body */}
      {openActivity && (
        <div className='card-body pt-5 '>
          {/* begin::Timeline */}
          <div className='timeline-label'>
            {activities &&
              activities.map((activity: any) => (
                <div className='timeline-item' key={activity.firtName}>
                  <div className='timeline-label fw-bold text-gray-800 fs-8'>
                    {activity.user.firstName}
                  </div>
                  <div className='timeline-badge'>
                    <i className={`fa fa-genderless ${color(activity.check_status.name)} fs-1`}></i>
                  </div>
                  <div
                    className='timeline-content d-flex flex-column'
                    style={{flexGrow: 0, minWidth: '20%'}}
                  >
                    <span className='fw-bold text-gray-800 ps-3'>{activity.check_status.name}</span>
                    <span className='fw-bold text-gray-500 ps-3 fs-8'>
                      {ActivityTimeCounter(activity.created_at)}
                    </span>
                    <div className='timeline-content fw-mormal text-muted ps-3'>
                      {activity.description}
                    </div>
                  </div>
                  {/* EDIT BUTTON */}
                  {EditNotePermision(
                    currentUser,
                    activity.user._id,
                    activity.check_status.name
                  ) && (
                    <button
                      onClick={() => openEditNoteFormAction(activity)}
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      title='edit'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen055.svg'
                        className='svg-icon-4'
                      />
                    </button>
                  )}
                </div>
              ))}

            {/* EDIT MODAL */}
            {openEditNoteForm && (
              <EditNoteForm openNoteFormAction={openEditNoteFormAction} activity={activity} />
            )}
            {/* end::Item */}
          </div>
          {/* end::Timeline */}
        </div>
      )}
      {/* end: Card Body */}
    </div>
  )
}

export {Activities}
