import {FC, useState, useCallback, useEffect, useMemo} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useParams} from 'react-router-dom'
import {
  isNotEmpty,
  QUERIES,
  stringifyRequestQuery,
  toAbsoluteUrl,
} from '../../../../../_metronic/helpers'
import {BL} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {Loader} from '../../../../../_metronic/helpers/components/loading/Loader'
import {createBL, updateBL, getBLById, getBL} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import UploadFile, {UploaddableFiles} from '../../../../../_metronic/helpers/components/UploadFile'
import {useMutation, useQueryClient} from 'react-query'
import {useAuth} from '../../../../modules/auth'
import {useQueryRequest} from '../core/QueryRequestProvider'
import BLSchemaValidation from './BLSchemaValidation'

type Props = {
  isUserLoading: boolean
  BL: BL
}

const BlEditModalForm: FC<Props> = ({BL, isUserLoading}) => {
  console.log(BL)

  const {id} = useParams()
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {currentUser} = useAuth()
  const queryClient = useQueryClient()
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [userForEdit] = useState<BL>({
    ...BL,
    shipment: BL.shipment?._id || '',
    bl_number: BL.bl_number || '',
    bl_file: BL.bl_file || [],
    aju_number: BL.aju_number || '',
    aju_file: BL.aju_file || [],
    skepTimbun_file: BL.skepTimbun_file || [],
    penjaluran_file: BL.penjaluran_file || [],
    e_billing_file: BL.e_billing_file || [],
    bpn_file: BL.bpn_file || [],
    sppb_file: BL.sppb_file || [],
    notul_file: BL.notul_file || [],
    others_file: BL.others_file || [],
    track: BL.track || 'green',
    status: BL.status || (BL.sppb_file?.length > 0 ? 'sppb' : 'draft'),
    checker: BL?.checker?._id || null,
    check_status: BL?.check_status?._id || '',
    created_by_company: BL?.created_by_company?._id || '',
    created_by: BL?.created_by?._id || '',

    // for author notification
    name: currentUser?.firstName,
    companyName: currentUser?.company.companyName,
    // for author notification
  })
  console.log(BL.status, BL.sppb_file?.length > 0)

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const appendToFormBl = (dataFormBL: FormData, values: BL) => {
    for (const [key, value] of Object.entries(values)) {
      if (!key.includes('init_') && !key.includes('delete_') && key.includes('_file')) {
        value.map((data: UploaddableFiles) => {
          dataFormBL.append(key, data.file)
        })
      } else if (key.includes('init_')) {
        dataFormBL.append(key, JSON.stringify(value))
      } else if (key.includes('delete_')) {
        dataFormBL.append(key, JSON.stringify(value))
      } else {
        dataFormBL.append(key, value)
      }
    }
  }

  const reFetchUpdateBlbyId = useMutation(() => getBLById(id), {
    // 💡 response of the mutation is passed to onSuccess

    onSuccess: () => {
      // ✅ update detail view directly
      console.log('successs UPDATE')
      queryClient.invalidateQueries([`bl-detail-${id}`])
    },
  })

  const reFetchUpdateBl = useMutation(() => getBL(query), {
    // 💡 response of the mutation is passed to onSuccess

    onSuccess: () => {
      // ✅ update detail view directly
      console.log('successs UPDATE')
      queryClient.invalidateQueries(`${QUERIES.BL_LIST}-${query}`)
    },
  })

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: BLSchemaValidation,
    onSubmit: async (values, {setSubmitting}) => {
      const dataFormBL = new FormData()
      console.log(values)

      // Function ada diatas
      appendToFormBl(dataFormBL, values)

      setSubmitting(true)
      try {
        if (isNotEmpty(values._id)) {
          await updateBL(dataFormBL)
          id ? await reFetchUpdateBlbyId.mutateAsync() : await reFetchUpdateBl.mutateAsync()
        } else {
          await createBL(dataFormBL, id)
        }
      } catch (ex: any) {
        console.error(ex.response.data)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  console.log('formik v', formik)

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-5'>Track</label>
            <div className='d-flex'>
              {/* begin::Radio */}
              <div className='form-check form-check-inline form-check-solid'>
                {/* begin::Input */}
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('track')}
                  name='track'
                  type='radio'
                  value='green'
                  id='kt_modal_update_track_option_0'
                  checked={formik.values.track === 'green'}
                  disabled={formik.isSubmitting || isUserLoading}
                />

                {/* end::Input */}
                {/* begin::Label */}
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                  <div className='fw-bolder' style={{color: '#9ac1ac'}}>
                    Green
                  </div>
                </label>
                {/* end::Label */}
              </div>
              {/* end::Radio */}
              <div />

              <div className='my-1' />

              {/* begin::Radio */}
              <div className='form-check form-check-inline form-check-solid'>
                {/* begin::Input */}
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('track')}
                  name='track'
                  type='radio'
                  value='yellow'
                  id='kt_modal_update_track_option_1'
                  checked={formik.values.track === 'yellow'}
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {/* end::Input */}
                {/* begin::Label */}
                <label className='form-check-label' htmlFor='kt_modal_update_track_option_1'>
                  <div className='fw-bolder ' style={{color: '#cfc191'}}>
                    Yellow
                  </div>
                </label>
                {/* end::Label */}
              </div>
              {/* end::Radio */}

              <div className=' my-1' />

              {/* begin::Input row */}
              {/* <div className=''> */}
              {/* begin::Radio */}
              <div className='form-check form-check-inline'>
                {/* begin::Input */}
                <input
                  className='form-check-input me-3'
                  {...formik.getFieldProps('track')}
                  name='track'
                  type='radio'
                  value='red'
                  id='kt_modal_update_track_option_2'
                  checked={formik.values.track === 'red'}
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {/* end::Input */}
                {/* begin::Label */}
                <label className='form-check-label' htmlFor='kt_modal_update_track_option_2'>
                  <div className='fw-bolder' style={{color: '#e77994'}}>
                    Red
                  </div>
                </label>
                {/* end::Label */}
              </div>

              {/* end::Radio */}
            </div>
          </div>
          {/* end::Input row */}

          {/* begin::Form row */}
          <div className='fv-row mb-3'>
            <label className='fw-bold fs-6 mb-2'>Status</label>
            <select
              disabled={BL.sppb_file?.length > 0 ? true : false}
              className={clsx(
                'form-select form-select-lg form-select-solid'
                // {'is-invalid': formik.touched.status && formik.errors.status},
                // {
                //   'is-valid': formik.touched.status && !formik.errors.status,
                // }
              )}
              data-control='select2'
              data-placeholder='Select Job type...'
              {...formik.getFieldProps('status')}
            >
              <option value='draft'>Draft</option>
              <option value='cek pib'>Cek PIB</option>
              <option value='spjm'>SPJM</option>
              <option value='bpn'>BPN</option>
              <option disabled value='sppb'>
                SPPB
              </option>{' '}
            </select>
          </div>
          {/* end::Form group */}

          <div className='fv-row mb-1'>
            <label className='required fw-bold fs-6 mb-2'>BL Number</label>

            <input
              placeholder='BL number'
              {...formik.getFieldProps('bl_number')}
              type='text'
              name='bl_number'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.bl_number && formik.errors.bl_number},
                {
                  'is-valid': formik.touched.bl_number && !formik.errors.bl_number,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.bl_number && formik.errors.bl_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.bl_number}</span>
                </div>
              </div>
            )}
          </div>
          {/* //INPUT FILE BL */}
          <div className='fv-row mb-5'>
            <UploadFile fieldName='bl_file' formik={formik} />
            {formik.errors.bl_file && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <>{formik.errors.bl_file}</>
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* PIB INPUT*/}
          <div className='fv-row mb-1'>
            <label className='required fw-bold fs-6 mb-2'>PIB Number</label>

            <input
              placeholder='Aju number'
              {...formik.getFieldProps('aju_number')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.aju_number && formik.errors.aju_number},
                {
                  'is-valid': formik.touched.aju_number && !formik.errors.aju_number,
                }
              )}
              type='text'
              name='aju_number'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.aju_number && formik.errors.aju_number && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.aju_number}</span>
              </div>
            )}
          </div>
          {/* //INPUT FILE PIB// */}
          <div className='fv-row mb-5'>
            <UploadFile fieldName='aju_file' formik={formik} />
            {formik.errors.aju_file && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <>{formik.errors.aju_file}</>
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* LABEL SHIPPING DOC */}
          <div className='fv-row '>
            <label className='fw-bold fs-6 mb-2'>Shipping Docs</label>
          </div>
          {/* //INPUT FILE SHIPPING DOC// */}
          <div className='fv-row mb-5'>
            <UploadFile fieldName='shipping_doc_file' formik={formik} />
            {formik.errors.shipping_doc_file && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <>{formik.errors.shipping_doc_file}</>
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* LABEL INVOICE */}
          <div className='fv-row '>
            <label className='fw-bold fs-6 mb-2'>Invoice</label>
          </div>
          {/* //INPUT FILE INVOICE// */}
          <div className='fv-row mb-5'>
            <UploadFile fieldName='invoice_file' formik={formik} />
            {formik.errors.invoice_file && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <>{formik.errors.invoice_file}</>
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* LABEL SKEP TIMBUN */}
          <div className='fv-row '>
            <label className='fw-bold fs-6 mb-2'>Skep Timbun</label>
          </div>
          {/* //INPUT FILE SKEP TIMBUN// */}
          <div className='fv-row mb-5'>
            <UploadFile fieldName='skepTimbun_file' formik={formik} />
            {formik.errors.skepTimbun_file && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <>{formik.errors.skepTimbun_file}</>
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* LABEL PENJALURAN */}
          <div className='fv-row'>
            <label className='fw-bold fs-6 mb-2'>Penjaluran</label>
          </div>
          {/* //INPUT FILE PENJALURAN// */}
          <div className='fv-row mb-5'>
            <UploadFile fieldName='penjaluran_file' formik={formik} />
            {formik.errors.penjaluran_file && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <>{formik.errors.penjaluran_file}</>
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* LABEL E-BILLING */}
          <div className='fv-row '>
            <label className='fw-bold fs-6 mb-2'>E-billing</label>
          </div>
          {/* //INPUT FILE E-BILLING// */}
          <div className='fv-row mb-5'>
            <UploadFile fieldName='e_billing_file' formik={formik} />
            {formik.errors.e_billing_file && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <>{formik.errors.e_billing_file}</>
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* LABEL COO */}
          <div className='fv-row '>
            <label className='fw-bold fs-6 mb-2'>Certificate of Origin</label>
          </div>
          {/* //INPUT FILE COO// */}
          <div className='fv-row mb-5'>
            <UploadFile fieldName='coo_file' formik={formik} />
            {formik.errors.coo_file && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <>{formik.errors.coo_file}</>
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* LABEL INSURANCE */}
          <div className='fv-row '>
            <label className='fw-bold fs-6 mb-2'>Insurance</label>
          </div>
          {/* //INPUT FILE INSURANCE// */}
          <div className='fv-row mb-5'>
            <UploadFile fieldName='insurance_file' formik={formik} />
            {formik.errors.insurance_file && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <>{formik.errors.insurance_file}</>
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* LABEL BPN */}
          <div className='fv-row '>
            <label className='fw-bold fs-6 mb-2'>BPN</label>
          </div>
          {/* //INPUT FILE BPN// */}
          <div className='fv-row mb-5'>
            <UploadFile fieldName='bpn_file' formik={formik} />
            {formik.errors.bpn_file && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <>{formik.errors.bpn_file}</>
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* LABEL CARGO POLICY */}
          <div className='fv-row '>
            <label className='fw-bold fs-6 mb-2'>Cargo Policy</label>
          </div>
          {/* //INPUT FILE CARGO POLICY // */}
          <div className='fv-row mb-5'>
            <UploadFile fieldName='cargo_policy_file' formik={formik} />
            {formik.errors.cargo_policy_file && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <>{formik.errors.cargo_policy_file}</>
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* LABEL SPPB */}
          <div className='fv-row'>
            <label className='fw-bold fs-6 mb-2'>SPPB</label>
          </div>
          {/* //INPUT FILE SPPB// */}
          <div className='fv-row mb-5'>
            <UploadFile fieldName='sppb_file' formik={formik} />
            {formik.errors.sppb_file && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <>{formik.errors.sppb_file}</>
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* LABEL NOTUL */}
          <div className='fv-row '>
            <label className='fw-bold fs-6 mb-2'>Notul</label>
          </div>
          {/* //INPUT FILE NOTUL// */}
          <div className='fv-row mb-5'>
            <UploadFile fieldName='notul_file' formik={formik} />
            {formik.errors.notul_file && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <>{formik.errors.notul_file}</>
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* LABEL OTHERS */}
          <div className='fv-row '>
            <label className='fw-bold fs-6 mb-2'>Others</label>
          </div>
          {/* //INPUT FILE OTHERS// */}
          <div className='fv-row mb-5'>
            <UploadFile fieldName='others_file' formik={formik} />
            {formik.errors.others_file && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    <>{formik.errors.others_file}</>
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* END INPUT */}
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            // disabled={false}
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <Loader />}
    </>
  )
}

export {BlEditModalForm}
