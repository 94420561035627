import {useListView} from '../../core/ListViewProvider'
import {BlListToolbar} from './BlListToolbar'
import {BlListGrouping} from './BlListGrouping'
import BlListSearchComponent from './BlListSearchComponent'
import {useQueryResponseData, useQueryResponseTittle} from '../../core/QueryResponseProvider'
import BackButton from '../../../../../../_metronic/helpers/components/BackButton'

const BlListHeader = () => {
  const {selected} = useListView()
  const shp: any = useQueryResponseTittle()
  console.log(shp)

  return (
    <>
      <BackButton />
      <div className='card-header align-items-center border-0 pt-6'>
        <div>
          <div className='text-gray-800 fs-2 fw-bolder me-1'>{shp?.shipment?.toUpperCase()}</div>
          <div className='badge badge-light-success  fs-6 fw-bolder me-1 my-2'>
            {shp?.vessel?.toUpperCase()}
          </div>
        </div>
        <BlListSearchComponent />
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          {/* begin::Group actions */}
          {/* {selected.length > 0 ? <BlListGrouping /> : <BlListToolbar />} */}
          <BlListToolbar />
          {/* end::Group actions */}
        </div>
        {/* end::Card toolbar */}
      </div>
    </>
  )
}

export {BlListHeader}
