import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {BlDetailWrapper} from './BL-list/BlList'
// import {Overview} from '../components/Overview'
// import {Settings} from './components/settings/Settings'
// import {AccountHeader} from './AccountHeader'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Bill Of Lading',
    path: 'doc/pages/shipment',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BlDetailPage: React.FC = () => {
  // console.log(Outlet);

  return (
    <Routes>
      <Route
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Detail</PageTitle>
            <BlDetailWrapper />
          </>
        }
      >
        <Route index element={<Navigate to='/doc/pages/shipment' />} />
      </Route>
    </Routes>
  )
}

export default BlDetailPage
