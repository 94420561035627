import {useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from '../core/QueryResponseProvider'
import {KTCardBody, KTSVG} from '../../../../_metronic/helpers'
import {ListPagination} from '../components/pagination/ListPagination'
import clsx from 'clsx'
import {useQueryClient, useMutation} from 'react-query'
import {removeChecker} from '../core/_requests'
import {ListLoading} from '../components/loading/ListLoading'

const Table = () => {
  // const dataDUMMY = [dataDummy]
  const dataPpjk = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const pagination = useQueryResponsePagination()
  const queryClient = useQueryClient()
  const [openModal, setOpenModal] = useState<boolean>(false)

  const closeDeleteModal = () => {
    setOpenModal(false)
  }

  type Iprops = {
    ppjk_id: string
    checkerId: string
  }

  const deleteItem = useMutation(
    ({ppjk_id, checkerId}: Iprops) => removeChecker(ppjk_id, checkerId),
    {
      onError: (err: any) => {
        console.log(err.response)
      },

      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        console.log('successs DELETE')
        queryClient.invalidateQueries('checker-list')
        closeDeleteModal()
      },
    }
  )

  const ppjkOnly = dataPpjk.filter((ppjk) => ppjk.companyName !== 'Grace Express Indonesia')

  const checkerLength = ppjkOnly.map((ppjk) => ppjk.checker?.length)
  let maxLength: number | undefined = 0
  let arrayMaxLength: number[] = []

  if (checkerLength.length > 0) {
    maxLength = checkerLength.reduce((max, length) => {
      if (max !== undefined && length !== undefined) {
        return Math.max(max, length)
      }
    })
  }

  if (maxLength) {
    for (let i = 1; i <= maxLength; i++) {
      arrayMaxLength.push(i)
    }
  }

  // if (dataBl[0]?.name) navigate('/admin')

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table table-row-dashed align-middle fs-6 gy-5 dataTable no-footer'
          // {...getTableProps()}
        >
          {/* COLUMN HEADER */}
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <td>No</td>
              <td>Company</td>
              {arrayMaxLength.map((a, i) => (
                <td key={i}>Checker {i + 1}</td>
              ))}
            </tr>
          </thead>

          {/* ROW */}
          <tbody>
            {ppjkOnly.length > 0
              ? ppjkOnly.map((ppjk, i) => {
                  return (
                    <tr key={i}>
                      <>
                        <th className='fs-7 fw-bolder text-gray-600'>{i + 1}</th>
                        <td className='fw-bold text-gray-600'>{ppjk.companyName}</td>

                        {/* ROW CHECKER */}
                        {ppjk.checker?.map((checker) => {
                          return (
                            <td className='fw-bolder text-gray-600' key={checker._id}>
                              {checker.firstName}
                              <a
                                href='#'
                                className={clsx('btn btn-icon ', '')}
                                data-kt-menu-trigger='click'
                                // data-kt-menu-attach='parent'
                                data-kt-menu-placement='right-start'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr072.svg'
                                  className='svg-icon-5 m-0'
                                />
                              </a>

                              <div
                                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-50px py-3'
                                data-kt-menu='true'
                              >
                                <div className='menu-item m-auto'>
                                  <a
                                    className='menu-link'
                                    title='Delete'
                                    onClick={async () =>
                                      await deleteItem.mutateAsync({
                                        ppjk_id: ppjk._id,
                                        checkerId: checker._id,
                                      })
                                    }
                                  >
                                    <i className='btn bi bi-trash fa p-0 text-danger' />
                                  </a>
                                </div>
                              </div>
                            </td>
                          )
                        })}
                      </>
                    </tr>
                  )
                })
              : isLoading || (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
          </tbody>
          {/* <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<PPJK>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0
              ? rows.map((row: Row<PPJK>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                })
              : isLoading || (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
          </tbody> */}
        </table>
      </div>
      {pagination.last_page > 1 && <ListPagination />}
      {isLoading && <ListLoading />}
    </KTCardBody>
  )
}

export {Table}
