import React, {useState, useMemo, useEffect, useContext} from 'react'
import {useQuery} from 'react-query'
import {getBLById} from '../core/_requests'
import {useParams} from 'react-router-dom'

import {BL, ErrorHandle} from '../core/_models'
import {
  createResponseContext,
  initialQueryResponse,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../core/QueryRequestProvider'

type Props = {}

const QueryResponseContext = createResponseContext<BL | ErrorHandle>(initialQueryResponse)
const BlDetailQueryResponse: React.FC<WithChildren> = ({children}) => {
  const {id} = useParams()
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    data: response,
    isFetching,
    refetch,
  } = useQuery(
    [`bl-detail-${id}`],
    () => {
      // setItemIdForUpdate(id)
      return getBLById(id)
    },
    {cacheTime: 0}
  )

  // const dataBl = data ? [data] : []

  // console.log('detail bl', dataBl)

  // return (
  //   <div>
  //     <BlDetailTable bl={dataBl} isFetching={isFetching} />
  //   </div>
  // )
  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  console.log(response)

  if (!response) {
    return []
  }
  if (response?.name == 'CastError' || response.errors) {
    const data = new Array()
    data.push(response)

    return data || []
  }

  if (response.data) {
    return [response.data]
  }
  return []
}

const useQueryResponseTittle = () => {
  const {response} = useQueryResponse()
  return response?.shp
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  BlDetailQueryResponse,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponseTittle,
}
