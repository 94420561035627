import React from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'
import {AdminPermission, HoPermission} from '../../../helpers/userAccess'

export function MenuInner() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      {/* <MenuItem title='Layout Builder' to='/builder' /> */}
      <MenuInnerWithSub
        title='Documents'
        to='/doc/pages'
        menuPlacement='bottom-start'
        menuTrigger='click'
        hasArrow={true}
      >
        {/* PAGES */}
        <MenuInnerWithSub
          title='Pages'
          to='/doc/pages'
          // fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen_ship.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem title='Shipment' to='/doc/pages/shipment' hasBullet={true} />
          <MenuItem title='Bill of Lading' to='/doc/pages/bl' hasBullet={true} />
          {/* <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
            <MenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            /> */}
          {/* <MenuInnerWithSub
            title='Wizards'
            to='/crafted/pages/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
            <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </MenuInnerWithSub> */}
        </MenuInnerWithSub>

        {/* ACCOUNT */}
        <MenuInnerWithSub
          title='Accounts'
          to='/crafted/accounts'
          fontIcon='bi-person'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
          <MenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        </MenuInnerWithSub>

        {/* ERRORS */}
        {/* <MenuInnerWithSub
          title='Errors'
          to='/error'
          fontIcon='bi-sticky'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </MenuInnerWithSub> */}

        {/* Widgets */}
        {/* <MenuInnerWithSub
          title='Widgets'
          to='/crafted/widgets'
          fontIcon='bi-layers'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <MenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <MenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <MenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <MenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <MenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </MenuInnerWithSub> */}
      </MenuInnerWithSub>
      {/* DATA */}
      {/* <MenuInnerWithSub
          title='Chat'
          to='/data/chat'
          icon='/media/icons/duotune/communication/com012.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
          >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub> */}
      {HoPermission(currentUser) && (
        <MenuInnerWithSub
          title='Data'
          to='/data'
          hasArrow={true}
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          <MenuInnerWithSub
            title='Company'
            to='/data/company'
            icon='/media/icons/duotune/finance/fin006.svg'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/data/company/ppjk' title='PPJK' hasBullet={true} />
            <MenuItem to='/data/company/checker' title='Checker' hasBullet={true} />
          </MenuInnerWithSub>

          <MenuItem
            icon='/media/icons/duotune/general/gen049.svg'
            to='/data/user-management/users'
            title='User management'
          />
        </MenuInnerWithSub>
      )}
      {AdminPermission(currentUser) && (
        <MenuInnerWithSub
          title='Tools'
          to='#'
          menuPlacement='bottom-start'
          menuTrigger='click'
          hasArrow={true}
        >
          <MenuItem
            icon='/media/icons/duotune/abstract/abs025.svg'
            to='/tools/invite-user'
            title='Invite User'
          />
        </MenuInnerWithSub>
      )}
      {/* <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}
