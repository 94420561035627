function Oclock24PM() {
  const currentDate = new Date()
  var dd = String(currentDate.getDate()).padStart(2, '0')
  var mm = String(currentDate.getMonth() + 1).padStart(2, '0') //January is 0!
  var yyyy = currentDate.getFullYear()
  const today = yyyy + '-' + mm + '-' + dd
  const Oclock24PM = new Date(`${today}T00:00:00`).getTime()
  return Oclock24PM
}

export function countToday(shipment: any) {
  const twentyFourOclock = Oclock24PM()
  const totalToday = shipment.map((shp: any) => {
    const doc: any = shp.created_at
    return twentyFourOclock < new Date(doc).getTime()
  })
  const total = totalToday.filter((result: any) => result === true)
  return total.length
}

export function countWeek(shipment: any) {
  const currentDate = new Date()
  const expiredDate = currentDate.getTime() - 7 * 24 * 60 * 60 * 1000

  const totalToday = shipment.map((shp: any) => {
    const doc: any = shp.created_at
    return expiredDate < new Date(doc).getTime()
  })
  const total = totalToday.filter((result: any) => result === true)
  return total.length
}

export function countMonth(shipment: any) {
  const currentDate = new Date()
  const expiredDate = currentDate.getTime() - 30 * 24 * 60 * 60 * 1000

  const totalToday = shipment.map((shp: any) => {
    const doc: any = shp.created_at
    return expiredDate < new Date(doc).getTime()
  })
  const total = totalToday.filter((result: any) => result === true)
  return total.length
}

export function NotifTimeCounter(creationDate: string) {
  const minutes = 1000 * 60
  const hours = minutes * 60
  const days = hours * 24
  const weeks = days * 7
  const time = new Date().getTime() - new Date(creationDate).getTime()

  let y
  if (time < 60000) {
    y = `${Math.floor(time / 1000)}s`
  } else if (time < 3600000) {
    y = `${Math.floor(time / minutes)}m`
  } else if (time < 86399999) {
    y = `${Math.floor(time / hours)}h`
  } else if (time < 604799999) {
    y = `${Math.floor(time / days)}d`
  } else if (time > 604800000) {
    y = `${Math.floor(time / weeks)}w`
  }

  return y
}

export function ActivityTimeCounter(creationDate: string) {
  const twentyFourOclock = Oclock24PM()
  let d = new Date(creationDate)
  const minutes = d.getMinutes()
  const minutesFormat = minutes.toString().padStart(2, '0')
  const hours = d.getHours()
  const docTime = new Date(creationDate).getTime()

  let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d)
  let mo = new Intl.DateTimeFormat('en', {month: 'short'}).format(d)
  let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d)

  if (twentyFourOclock < docTime) {
    return `${hours} : ${minutesFormat}`
  } else {
    return `${da} ${mo} ${ye}`
  }
}
