import * as React from 'react'
import {ID, KTSVG} from '../../helpers'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {NotifTimeCounter} from '../../helpers/time/CountDocByTime'
import {useAuth} from '../../../app/modules/auth'
import {isReadAction} from './_request'

export interface INotificationListProps {
  notifications: any[]
}

export default function NotificationList({notifications}: INotificationListProps) {
  const {currentUser} = useAuth()

  const checkIsRead = (user: any[]) => {
    const userIndex = user.findIndex((id: string) => id == currentUser?._id)
    return userIndex == -1 ? false : true
  }

  const readAction = (user_id: ID, notif_if: string) => {
    return (event: React.MouseEvent) => {
      isReadAction(user_id, notif_if)
      event.preventDefault()
    }
  }

  return (
    <div className='scroll-y mh-325px h-325px my-5 px-4'>
      {notifications.map((notif, index) => (
        <div
          key={`alert${index}`}
          className={`d-flex flex-stack py-2 my-2 rounded position-relative ${
            checkIsRead(notif.user_read) || 'bg-light'
          }`}
        >
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-35px m-4 '>
              <span className={clsx('symbol-label', `bg-light-${notif.state}`)}>
                {' '}
                <KTSVG path={notif.icon} className={`svg-icon-2 svg-icon-${notif.state}`} />
              </span>
            </div>

            <div className='mb-0 me-2' onClick={readAction(currentUser?._id, notif._id)}>
              <Link
                to={notif.link ? notif.link : '#'}
                className={`${
                  checkIsRead(notif.user_read) ? 'text-gray-600' : 'text-gray-800'
                } fs-6 text-hover-primary  fw-bold`}
              >
                {notif.title}
              </Link>
              <div className={`text-gray-600 fs-7`}>{notif.description}</div>
            </div>
          </div>
          <span className='badge badge-light fs-8'>{NotifTimeCounter(notif.created_at)}</span>
          {/* BULLET ICON */}
          {checkIsRead(notif.user_read) || (
            <span className='bullet bullet-dot bg-primary h-6px w-6px position-absolute translate-middle top-0 start-100'></span>
          )}
        </div>
      ))}
    </div>
  )
}
