export function ExpiredCheck(date: any) {
  const currentDate = Date.now()
  const created = new Date(date)
  const expiredTime = created.getTime() + 7 * 24 * 60 * 60 * 1000

  if (currentDate > expiredTime) {
    return 'Expired'
  } else {
    return 'not expired'
  }
}
