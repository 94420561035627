export function timeSince(date: any) {
  const newDate = +new Date(date)
  var seconds = Math.floor((+new Date() - newDate) / 1000)

  var interval = seconds / 31536000

  // if (interval > 1) {
  //   return Math.floor(interval) + ' years'
  // }
  // interval = seconds / 2592000
  // if (interval > 1) {
  //   return Math.floor(interval) + ' months'
  // }
  interval = seconds / 172800
  if (interval > 1) {
    return dateFormat(date)
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + ' days'
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + ' hours'
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + ' minutes'
  }
  return Math.floor(seconds) + ' seconds'
}
var aDay = 24 * 60 * 60 * 1000

export function dateFormat(date: any) {
  let d = new Date(date)
  let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d)
  let mo = new Intl.DateTimeFormat('en', {month: 'short'}).format(d)
  let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d)
  return `${da} ${mo} ${ye}`
}
