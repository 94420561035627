import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
// import {ShipmentHeader} from './components/header/ShipmentHeader'
import {BlListHeader} from './components/header/BlListHeader'
import {BlTable} from './table/BlTable'
import {BlEditModal} from './bl-edit-modal/BlEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import {QueryClientProvider, QueryClient} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {BlDetailQueryResponse} from './bl-detail/BlDetailQueryResponse'
import {BlDetailHeader} from './components/header/BlDetailHeader'
import {BlDetailTable} from './bl-detail/BlDetailTable'
import {AllDataBlTable} from './table/AllDataBlTable'
import {AllBlHeader} from './components/header/AllBlHeader'
import {AllBLContextProvider} from './core/AllBLContextProvider'
import ViewPDF from '../../../../_metronic/helpers/components/VIewPDF/ViewPDF'

const BlList = () => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <KTCard>
        {/* <BlListHeader /> */}
        <BlTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <BlEditModal />}
    </>
  )
}
const AllDataBl = () => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <KTCard>
        <AllBLContextProvider>
          <AllBlHeader />
          <AllDataBlTable />
        </AllBLContextProvider>
      </KTCard>
      {itemIdForUpdate !== undefined && <BlEditModal />}
    </>
  )
}
const BlDetail = () => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <KTCard>
        {/* <BlDetailHeader /> */}
        <BlDetailTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <BlEditModal />}
    </>
  )
}

const BlDetailWrapper = () => {
  return (
    <ListViewProvider>
      <BlDetailQueryResponse>
        <BlDetail />
      </BlDetailQueryResponse>
    </ListViewProvider>
  )
}

const BlListWrapper = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <BlList />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

const AllDataBlWrapper = () => {
  return (
    <QueryRequestProvider>
      {/* <QueryResponseProvider> */}
      <ListViewProvider>
        <AllDataBl />
      </ListViewProvider>
      {/* </QueryResponseProvider> */}
    </QueryRequestProvider>
  )
}

export {BlListWrapper, BlDetailWrapper, AllDataBlWrapper}
