import react from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {KTSVG} from './KTSVG'

const BackButton = () => {
  const navigate = useNavigate()

  return (
    <div className='m-5 '>
      <button onClick={() => navigate(-1)} type='button' className='btn btn-light-primary p-2'>
        <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
      </button>
    </div>
  )
}

export default BackButton
