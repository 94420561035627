import * as Yup from 'yup'

const fileValidation = () =>
  Yup.mixed().test('fileFormat', 'Uploaded file has unsupported format.', (values) => {
    console.log('test', 1)

    let valid = true
    values.map((value?: any) => {
      console.log(value)
      if (value.file) {
        if (!(!value?.file.type || ['application/pdf', 'image/jpeg'].includes(value?.file?.type))) {
          valid = false
        }
      }
    })
    return valid
  })

const BLSchemaValidation = Yup.object().shape({
  bl_number: Yup.string().required('BL number is required'),
  aju_number: Yup.string().required('Aju number is required'),
  bl_file: fileValidation(),
  aju_file: fileValidation(),
  skepTimbun_file: fileValidation(),
  penjaluran_file: fileValidation(),
  e_billing_file: fileValidation(),
  bpn_file: fileValidation(),
  sppb_file: fileValidation(),
  notul_file: fileValidation(),
  shipping_doc_file: fileValidation(),
  invoice_file: fileValidation(),
  coo_file: fileValidation(),
  insurance_file: fileValidation(),
  others_file: fileValidation(),
})

export default BLSchemaValidation
