import {ListToolbar} from './ListToolbar'
import ListSearchComponent from './ListSearchComponent'

const ListHeader = () => {
  return (
    <div className='card-header align-items-center border-0 pt-6'>
      <div>
        <div className='text-gray-800 fs-2 fw-bolder me-1'>Checker List</div>
        <div className='badge badge-light-success  fs-6 fw-bolder me-1 my-2'></div>
      </div>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <ListToolbar />
        {/* begin::Group actions */}
        {/* {selected.length > 0 ? <BlListGrouping /> : <BlListToolbar />} */}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ListHeader}
