import {User} from '../../app/modules/auth'
import {ID} from './crud-helper/models'
import {ROLES} from './roles'
import {SPPBExpired} from './time/SPPBExpired'

export const HoPermission = (currentUser?: User) => {
  if (currentUser?.role === ROLES.ADMIN || currentUser?.role === ROLES.HO) return true
  return false
}

export const AdminPermission = (currentUser?: User) => {
  if (currentUser?.role === ROLES.ADMIN) return true
  return false
}

export const projectOwnerCanView = (currentUser?: User, company_id?: ID, sppb_date?: string) => {
  if (SPPBExpired(sppb_date)) {
    return HoPermission(currentUser)
  } else {
    if (
      currentUser?.role === ROLES.ADMIN ||
      currentUser?.role === ROLES.HO ||
      currentUser?.company._id === company_id
    )
      return true
    return false
  }
}

export const ProjectOwnerPermision = (currentUser?: User, company_id?: ID) => {
  if (
    currentUser?.role === ROLES.ADMIN ||
    currentUser?.role === ROLES.HO ||
    currentUser?.company._id === company_id
  )
    return true
  return false
}

export const NotePermision = (currentUser?: User, company_id?: ID, checker_id?: ID) => {
  if (
    // currentUser?.role === ROLES.ADMIN ||
    currentUser?._id === checker_id ||
    currentUser?.company._id === company_id
  )
    return true
  return false
}

export const EditNotePermision = (currentUser?: User, checker_id?: ID, status?: string) => {
  if (
    // currentUser?.role === ROLES.ADMIN ||
    currentUser?._id === checker_id &&
    status !== 'Created'
  )
    return true
  return false
}
