import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {useNavigate} from 'react-router-dom'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from '../core/QueryResponseProvider'
import {BlColumns} from './columns/_columns'
import {BL, ErrorHandle} from '../core/_models'
import {Loader} from '../../../../../_metronic/helpers/components/loading/Loader'
import {BlListPagination} from '../components/pagination/ListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {NoPageFound} from '../../../../modules/errors/components/NoPageFound'
import {BlListHeader} from '../components/header/BlListHeader'
import {ShowPage} from '../components/pagination/ShowPage'

const BlTable = () => {
  const dataBl = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const pagination = useQueryResponsePagination()
  const data = useMemo(() => dataBl, [dataBl])
  const columns = useMemo(() => BlColumns, [])
  const navigate = useNavigate()
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  if (dataBl[0]?.name) return <NoPageFound />

  return (
    <>
      <BlListHeader />

      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<BL>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0
                ? rows.map((row: Row<BL>, i) => {
                    prepareRow(row)
                    return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                  })
                : isLoading || (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
            </tbody>
          </table>
        </div>

        <div className='row mt-5'>
          {rows.length > 0 && <ShowPage tableName='show-bl-record' />}
          {pagination.last_page > 1 && <BlListPagination />}
        </div>

        {isLoading && <Loader top='120%' />}
      </KTCardBody>
    </>
  )
}

export {BlTable}
