import {Dispatch, SetStateAction} from 'react'

export type ID = undefined | null | number | string

export type PaginationState = {
  page: number
  last_page: number
  items_per_page: 20 | 50 | 75 | 100
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
  total?: string
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}
export type SortCompanyState = {
  sortCompany?: ID
}

export type DateState = {
  start_date?: string
  end_date?: string
}

export type ErrorHandle = {
  kind: string
  message: string
  name: string
  path: string
  reason: {}
  stringValue: string
  value: {_id: string}
  valueType: {}
}

export type Response<T> = {
  data?: T
  status?: number
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination: PaginationState
  }
  name?: string
  errors?: string
  shp?: {}
  allShipment?: T
}

export type ResponseSHP<T> = {
  data?: T
  payload?: {pagination: PaginationState}
  shp?: T
  allShipment?: T
}
export type ResponsePPJK<T> = {
  data?: T
  payload?: {pagination: PaginationState}
}

export type ResponseBL<T> = {
  data?: T
  bl?: T
  shipment_number?: T
  vessel_name?: T
  bc20?: T
  allBl?: T
  payload?: {pagination: PaginationState}
}
export type ResponseUser<T> = {
  data?: T
  payload?: {pagination: PaginationState}
}

export type QueryState = PaginationState &
  SortState &
  FilterState &
  SearchState &
  SortCompanyState &
  DateState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  items_per_page: 20,
  last_page: 0,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>>
  responseNotif?: any[]
  responseShp?: ResponseSHP<Array<T>>
  responseBl?: ResponseBL<Array<T>>
  responsePpjk?: ResponsePPJK<Array<T>>
  error?: Response<Array<T>>
  refetch: () => void
  isLoading: boolean
  query?: string
  queryBL?: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
  title?: {}
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
  title: {},
}
